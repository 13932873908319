import queryString from "query-string";
import { startCase } from "lodash/string";
import { strToSnakeCase } from "../string";
import { omit } from "../Q";
import { operatorMap } from "./operator-map";
import parseValue from "../parse-value";
import { initRequest } from "../actionMethods";

const sortTypesMap = {
    'Ascending': 'asc',
    'Descending': 'desc'
}

const extractFieldValue = (formValues, table, rowIndex) => (field) => {
    if(!formValues) return null;
    return table && !isNaN(rowIndex) && formValues[table] && formValues[table][rowIndex]
        ? formValues[table][rowIndex][field]
        : formValues[field]
}
export const formPayload = payload => {
    const { sections, flowData } = payload;

    const fieldGroups = sections.map((section, secIdx) => {
        const {
            displayName,
            sectionIndex,
            rows,
            fields,
            ...rest
        } = section;
        const rowFields = rows.reduce((result, row, rowIndex) => {
            const mapFieldTypes = {
                'remote-lookup': 'remoteurl'
            };
            return [
                ...result,
                ...row.fields.map(({ id, headers, fieldType, fieldName, ...field }, columnIndex) => {
                    return {
                        ...field,
                        ...(id || fieldName ? { name: strToSnakeCase(id || fieldName) } : {}),
                        ...(headers?.length ? { headers } : {}),
                        isTable: field.isTable,
                        displayName: fieldName,
                        editorType: mapFieldTypes[fieldType] || fieldType,
                        rowIndex,
                        columnIndex,
                        reservedFieldNames: [],
                    }
                })
            ]
        }, []);

        return {
            ...rest,
            displayName,
            sectionIndex,
            name: rest.name ? rest.name : strToSnakeCase(displayName) + "_" + secIdx,
            orderIndex: sectionIndex,
            minimumRowCount: 0,
            maximumRowCount: 0,
            fields: rowFields,
        }
    });

    return {
        // strToSnakeCase(flowData.displayName)
        // flowData.formName ? flowData.formName : strToSnakeCase(flowData.displayName)
        formName: strToSnakeCase(flowData.formName),
        displayName: flowData.displayName,
        isPublished: Boolean(flowData.isPublished),
        fieldGroups,
    }
}

export const listKeyValuePayload = payload => {
    const { id, categories, values, items, name: key_name, description } = payload

    return {
        ...(id ? { id } : {}),
        key_name,
        categories: categories.join(', '),
        values: values || items,
        description,
    }
}

export const dataTablePayload = payload => {
    const { fields = [], query, page = 1, orders = [], filters = {}, sizePerPage = 10 } = payload;
    const columns = fields.map((field) => {
        const fieldId = field.id || field.dataField || String(field);
        return {
            data: fieldId,
            name: fieldId,
            searchable: true,
            orderable: true,
            searchValue: null,
            searchRegex: false
        }
    })
    return {
        page,
        columns,
        orders,
        search: filters?.rules?.length ? null : {
            searchValue: query || '',
            searchRegex: false
        },
        length: sizePerPage || 10,
        filters
    }
}

export const remoteLookupPayload = async ({ payload, formValues = {}, table, rowIndex, govHeaders = [] }) => {
    const {
        url = '',
        requestType: httpMethod,
        responseFormat: contentType,
        responsePath: tokenizer,
        submitAllFields,
        id,
        headers = [],
        body = [],
        params = [],
        addGovHeaders,
    } = payload
    const urlObject = url ? new URL(url) : {}
    const parsedUrl = queryString.parse(urlObject?.search)
    const getFieldValue = (field) => {
        return table &&
        !isNaN(rowIndex) &&
        formValues[table] &&
        formValues[table][rowIndex]
        && !formValues.hasOwnProperty(field)
            ? formValues[table][rowIndex][field]
            : formValues[field]
    }
    const getDatasetValue = async ({ value, datasetKey, datasetColumn }) => {
        const request = initRequest()
        const response = await request('getDataByKey', { datasetId: value, key: getFieldValue(datasetKey) })
        if (response?.data && response?.data[datasetColumn]) {
            return response.data[datasetColumn]
        }
        return null
    }
    const fieldsWalker = async fields => {
        let result = {}
        if (fields.length) {
            await Promise.all(fields.map(async field => {
                try {
                    if (field.type === 'data') {
                        const response = await getDatasetValue(field)
                        if (response) {
                            result[field.name] = response
                        } else {
                            result[field.name] = field.value
                        }
                    } else if (field.type === 'field') {
                        result[field.name] = getFieldValue(field.value)
                    } else {
                        result[field.name] = field.value
                    }
                } catch (e) {
                    console.error(e)
                }
            }))
        }
        return result
    }
    const headersWalker = async fields => {
        let result = []
        if (fields.length) {
            await Promise.all(fields.map(async field => {
                try {
                    if (field.type === 'data') {
                        const response = await getDatasetValue(field)
                        if (response) {
                            result.push({
                                key: field.name,
                                value: response,
                            })
                        } else {
                            result.push({
                                key: field.name,
                                value: field.value,
                            })
                        }
                    } else if (field.type === 'field') {
                        result.push({
                            key: field.name,
                            value: getFieldValue(field.value),
                        })
                    } else {
                        result.push({
                            key: field.name,
                            value: field.value,
                        })
                    }
                } catch (e) {
                    console.error(e)
                }
            }))
        }
        return result
    }

    const postBody = {
        ...(httpMethod === 'POST' ? await fieldsWalker(body) : {}),
        ...(httpMethod === 'POST' && submitAllFields
            ? omit(formValues, id)
            : {}
        )
    }

    const urlParams = await fieldsWalker(params)
    const requestHeaders = [
        ...await headersWalker(headers),
        ...(addGovHeaders && govHeaders?.length ? govHeaders : []),
    ]
    const modifiedUrl = url
        ? queryString.stringifyUrl({
            url: url.split('?').shift(),
            query: {
                ...(parsedUrl || {}),
                ...urlParams,
            }
        })
        : ''

    return {
        httpMethod,
        tokenizer,
        url: modifiedUrl,
        contentType: contentType?.toLowerCase(),
        selectMultiple: true,
        ...(
            requestHeaders.length
                ? { headers: requestHeaders }
                : {}
        ),
        ...(
            Object.keys(postBody).length
                ? { bodyContent: JSON.stringify(postBody) }
                : {}
        ),
        urlParams
    }
}

export const lookupPayload = (payload, formValues, table, rowIndex) => {
    const {
        fieldType,
        flowToLookUp,
        moduleId,
        isTableLookup,
        tableName,
        filters,
        isDataset,
        sortValuesBy: sortBy,
        sortType,
        rawFilters,
        selectFieldsByThisProcess
    } = payload

    const getFieldValue = extractFieldValue(formValues, table, rowIndex)
    const { combinator = '' } = rawFilters || {}
    const fieldNames = fieldType === "pull-data" ? ['id', ...selectFieldsByThisProcess] : ["*"];
    return {
        moduleId: moduleId || flowToLookUp,
        fieldName: fieldNames,
        isTable: isTableLookup,
        tableName,
        isDataset,
        ...(sortBy ? {
            sortBy,
            sortType: sortTypesMap[sortType],
        }: {}),
        ...((filters || []).length ? {
            filter: {
                matchesAll: combinator.toLowerCase() === 'and',
                filters: filters.map(filter => {
                    const { field, fieldType, operator, type, value, valueFieldType } = filter
                    return {
                        field,
                        value: type === 'Field'
                            ? parseValue(getFieldValue(value), fieldType || valueFieldType)
                            : parseValue(value, fieldType || valueFieldType),
                        operator: operatorMap(operator),
                        ignoreCase: true,
                    }
                })
            }
        } : {})
    }
}

export const aggregateLookupPayload = (payload, matchesAll = true) => {
    const {
        moduleId,
        isTableLookup,
        tableName,
        fieldToAggregate: field,
        aggregationType: aggregateType,
        sortValuesBy: sortBy,
        sortType,
        filters,
    } = payload;

    return {
        field,
        moduleId,
        isTable: isTableLookup,
        tableName,
        aggregateType: String(aggregateType).toLowerCase(),
        ...(sortBy ? {
            sortBy,
            sortType,
        } : {}),
        ...((filters || []).length ? {
            filter: {
                matchesAll: matchesAll,
                filters: filters.map(({ field, operator, value }) => ({
                    field,
                    value,
                    operator: operatorMap(operator),
                    ignoreCase: true,
                }))
            }
        } : {})
    }
}

export const userLookupPayload = (payload, formValues) => {
    const {
        filters,
        rawFilters,
    } = payload
    const { combinator = '' } = rawFilters || {}
    const getFieldValue = extractFieldValue(formValues)
    return {
        ...((filters || []).length ? {
            matchesAll: combinator.toLowerCase() === 'and',
            filters: filters.map(filter => {
                const { field, fieldType, operator, type, value } = filter
                return {
                    field,
                    value: type === 'Field'
                        ? parseValue(getFieldValue(value), fieldType)
                        : parseValue(value, fieldType),
                    operator: operatorMap(operator),
                    ignoreCase: true,
                }
            })
        } : {})
    }
}

export const syncWorkflowItems = workflow => {
    workflow.map(wf => {
        if (wf.type === 'goto') {
            const goto = workflow.find(step => step.id === wf.goto?.value)
            if (goto && goto.name !== wf.goto.label) {
                wf.goto.label = goto.name
            }
        }
        return wf;
    })

    return workflow
}

export const workflowPayload = (payload) => {
    const { elements, validUserFields } = payload || []   
    const validateAssignee = (rawActivity) =>{ 
        const { name, assignToCollectionExpression } = rawActivity;
        if(assignToCollectionExpression){
            const { expression } = assignToCollectionExpression;
            let assigneeList = JSON.parse(expression);
            let validAssignee = assigneeList.reduce((result, assignee) => {
                let assigneeField = assignee.approver_id.split(".")[1];
                if (!assigneeField || (assigneeField && validUserFields?.includes(assigneeField))) {
                    result.push(assignee);
                }
                return result;
            }, [])
            if (validAssignee.length === 0) {
                throw { message: `Assignee for ${name} is no longer available` }
            }
            return {
                ...rawActivity,
                assignToCollectionExpression: {
                    ...assignToCollectionExpression,
                    expression: JSON.stringify(validAssignee)
                }
            }     
        } 
        return rawActivity;
    }  
    const activities = elements.filter(el => !el.source && !el.target).map(act => {
        const { position, data, id, ...activity } = act
        const { raw = {} } = data || {}
        const { name, title, description, nodeDescription, ...rawProperties } = raw
        const properties = Object.entries(rawProperties).reduce((acc, prop) => {
            const [key, value] = prop
            const propName = startCase(key).replace(/\s/g, '')
            const expressionTypeMap = {
                SupportedStatusCodes: 'Json',
                Branches: 'Json',
                OutcomeNames: 'Json',
                PossibleOutcomes: 'Json',
            }
            const propNameMap = {
                ConditionExpression: 'Condition',
                ModuleId: 'ApplicationModuleId',
                ValueExpression: 'Value',
            }
            const excludeSyntax = [
                'SourceType',
                'QueryExpressionType',
                'SingleResult',
            ]
            const parsePropValue = value => {
                switch (true) {
                    case Array.isArray(value):
                        return JSON.stringify(value.filter(v => Boolean(v)))
                    case typeof value === 'string':
                    default:
                        return value
                }
            }
            const syntaxValue = value?.syntax || expressionTypeMap[propName] || 'Literal'
            const expValue = parsePropValue(
                ![undefined, null].includes(value?.expression)
                    ? value.expression
                    : value
            )
            const elsaPropName = propNameMap[propName] || propName
            const elsaProp = {
                name: elsaPropName,
                ...excludeSyntax.includes(elsaPropName)
                    ? {}
                    : { syntax: value?.syntax },
                expressions: ![undefined, null].includes(expValue)
                    ? { [syntaxValue]: expValue }
                    : {},
            }
            return [
                ...acc,
                elsaProp,
            ]
        }, [])
        const activityTypeMap = {
            IfElse: 'If',
        }
        return {
            ...activity,
            name,
            type: activityTypeMap[activity?.type] || activity?.type,
            activityId: id,
            displayName: title || name || activity?.type,
            description: raw?.nodeDescription,
            left: position?.x,
            top: position?.y,
            state: validateAssignee(raw),
            properties,
            persistWorkflow: false,
            loadWorkflowContext: false,
            saveWorkflowContext: false,
        }
    })
    const connections = elements.filter(el => el.source && el.target).map(conn => {
        const { sourceHandle, source, target, ...state } = conn
        return {
            state,
            outcome: sourceHandle || 'Done',
            sourceActivityId: source,
            targetActivityId: target,
        }
    })
    return {
        draft: {
            activities,
            connections,
        }
    }
}

export default {
    formPayload,
    listKeyValuePayload,
    dataTablePayload,
    remoteLookupPayload,
    lookupPayload,
    syncWorkflowItems,
    workflowPayload,
}
