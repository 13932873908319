/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import objectPath from "object-path";

import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import Ava from "../../../../app/components/Ava";

export function QuickUserToggler() {
  // const { user } = useSelector((state) => state.auth.config);
  const { user, imgUrl } = useSelector(
    ({ auth }) => ({
      user: auth?.config?.userInfo?.username,
      imgUrl: "/api/sys/User/ProfilePicture?id=" + auth?.config?.userInfo?.uid + "&x-token=" + auth.authToken,
    }),
    shallowEqual
  );
  // console.log("teh data user = ", token, uid, username);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    // console.log("services = ",uiService)
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  // console.log('user: ', user);

  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                Hi,
              </span>
              <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                {user}
              </span>
              {/* <span className="symbol symbol-35 symbol-light-success">
                <span className="symbol-label font-size-h5 font-weight-bold">
                  A
                </span>
              </span> */}
              <Ava
                contain
                frameClass="d-block symbol symbol-35 symbol-light-success"
                alt={user}
                src={imgUrl}
                w={35} 
                h={35}
                bg="#C9F7F5"
                labelColor="symbol-label"
              >
                {/* <i className="symbol-badge bg-success" /> */}
              </Ava>
            </div>
          </div>
        </OverlayTrigger>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
