export const isJsonString = (str) => {
    try {
        return typeof JSON.parse(str) === "object";        
    } catch (e) {           
        return false;
    }
}

export const parseJsonString = (str) => {
    if (isJsonString(str)) {
        return JSON.parse(str);
    }
    return str;
}