import { isObj } from './Q';

// === Array / Object Manipulation ===
function arrMoveMutate(arr, fr, to){
	// arr.splice(to < 0 ? arr.length + to : to, 0, arr.splice(fr, 1)[0]);

	const startIndex = fr < 0 ? arr.length + fr : fr;
	if (startIndex >= 0 && startIndex < arr.length) {
		const endIndex = to < 0 ? arr.length + to : to;

		const [item] = arr.splice(fr, 1);
		arr.splice(endIndex, 0, item);
	}
}

function arrMove(arr, fr, to){ // arrayMove
	arr = [...arr]; // arr.slice();
	arrMoveMutate(arr, fr, to);
	return arr;
}

/** 
	@arr	: Array to add 
	@i		: index position to add item 
	@v		: value new item 
*/
function addInto(arr, i, v){
	arr = [...arr]; // arr.slice();
	arr.splice(i, 0, v);
	return arr;
}

function cloneDeep(val){
	return (isObj(val) || Array.isArray(val)) && JSON.parse(JSON.stringify(val));
}

/** 
	@data		: Array object rescursive
	@key		: key name to search
	@keyVal	: key value to compare search
	@child	: Children key name with Array value to search more
*/
/* const recursiveFindIndex = (data, key, keyVal, child = "files") => {
	if(!data) return;

	let idx;

	data.forEach((el, i) => {// findIndex
		if(el[child]){
			const getIdx = el[child].findIndex(f => f[key] === keyVal);
			if(Q.isNum(getIdx) && getIdx >= 0){ // Q.isNum(getIdx)
				idx = getIdx;
			}else{
				recursiveFindIndex(el[child], key, keyVal, child);
			}
		}
		else if(el[key] === keyVal){
			idx = i;
		}
	});

	return Q.isNum(idx) && idx >= 0 ? idx : false;
} */

/** 
	@data			 : Array object rescursive
	@key			 : Children key name with Array value to search more
	@parentObj : Object to add in parent
	@childObj	 : Object to add in children
*/
// OPTION Name: recursiveUpdate

/* function recursiveEdit(data, {
	key = "files", 
	parentObj = Q.noop, // {}
	childObj = Q.noop
} = {}){ 
	if(!data) return [];

	let tree = [];
	
	data.forEach((item) => {
		let pObj = parentObj ? parentObj(item) : {};
		let obj = {
			...item, 
			...pObj
		};

		if(Array.isArray(item[key])){
				...item, 
			tree.push({
				...pObj,  
				[key]: recursiveEdit(item[key], key)
			});
		}else{
			let cObj = childObj ? childObj(item) : {};
			tree.push({
				...item, // pObj
				...cObj
				// icon: "i-color qi-" + item.name.split(".").pop() // getExt(item.name)
			});
		}
	});
	
	return tree;
} */

// https://github.com/xnf/recursively
// function recursiveEdit(arr, cb, keyChild = "children"){
// 	if(Array.isArray(arr)){
// 		let res = cloneDeep(arr);// [ ...arr ];
// 		res.forEach((item, idx) => {
// 			if(Array.isArray(item)){
// 				recursiveEdit(item, cb, keyChild);
// 			}else{
// 				let cbResult = cb(item, idx, res);
// 				if(typeof cbResult !== 'undefined'){
// 					res[idx] = cbResult;
// 				}
// 				if(item && Array.isArray(item[keyChild])){
// 					recursiveEdit(item[keyChild], cb, keyChild);
// 				}
// 			}
// 		});

// 		return res;
// 	}
// }

// OK
// function recursiveFind(arr, key, findVal, keyChild = "files"){
// 	// The downside of reduce() is you're forced to iterate to the end of each array at each level of recursion. A simple for loop does not have that limitation
//   // return arr.reduce((a, item) => {
//   //   if (a) return a;
//   //   if (item.id === id) return item;
//   //   if (item.files) return findId(id, item.files);
//   // }, null);

//   for(let node of arr){
//     if(node[key] === findVal) return node;
//     if(node[keyChild]){
//       let child = recursiveFind(node[keyChild], key, findVal, keyChild);
//       if(child) return child;
//     }
//   }
// }

// function sortDirBy(datas, by = "directory"){ // sortByFile
//   if(Array.isArray(datas)){
//     return datas.sort((a, b) => {
//       //let nameA = a.name.toUpperCase(); // ignore upper and lowercase
//       //let nameB = b.name.toUpperCase(); // ignore upper and lowercase
//       if(a.type === by){ // nameA < nameB
//         return -1;
//       }
//       if(b.type === (by === "file" ? "directory" : "file")){
//         return 1;
//       }
//       return 0;// names must be equal
//     });
//     // console.log('res: ', res);
//     // return res;
//   }
// }

// function recursiveSortDir(datas, {
// 	key = "children", 
// 	by = "directory"
// } = {}){ 
// 	if(Array.isArray(datas)){
//     // let data = sortDirBy(cloneDeep(datas), by);
//     let tree = [];
    
//     sortDirBy(cloneDeep(datas), by).forEach((item) => {
//       if(Array.isArray(item[key])){
//         tree.push({
//           ...item, 
//           [key]: recursiveSortDir(item[key], { key, by })
//         });
//       }else{
//         tree.push(item);
//       }
//     });
  
//     return tree;// .reverse()
//   }
// }

// https://stackoverflow.com/questions/64370762/moving-an-object-in-a-recursive-array
/* const findById = (data, key, findVal, keyChild = "files") => data.reduce((r, x) => r != null ? r : x[key] === findVal ? x : findById(x[keyChild], key, findVal, keyChild) || null, null);

const removeById = (data, key, findVal, keyChild = "files") => data.reduce((r, x) => x[key] === findVal ? r : [ ...r, { ...x, [keyChild]: removeById(x[keyChild], key, findVal, keyChild)}], [])

const addById = (data, key, findVal, newVal, keyChild = "files") => data.reduce((r, x) => [ ...r, { ...x, [keyChild]: [ ...addById(x[keyChild], key, findVal, newVal, keyChild), ...(x[key] === findVal ? [newVal] : [])]}], [])

// Main function
const moveById = (data, {
	// id, 
	toId, 
	findVal, 
	key = "path", 
	keyChild = "files"
} = {}) => addById(findById(data, key, findVal, keyChild), key, findVal, removeById(data, key, findVal, keyChild), keyChild, toId); */

// const findById = (data, id) => data.reduce((r, x) => r != null ? r : x.id === id ? x : findById(x.files, id) || null, null);

// const removeById = (data, id) => data.reduce((r, x) => x.path === id ? r : [ ...r, { ...x, files: removeById(x.files, id)}], []);

// const addById = (data, id, newVal) => data.reduce((r, x) => [ ...r, { ...x, files: [ ...addById(id, newVal, x.files), ...(x.id === id ? [newVal] : [])]}], []);

// // Main function
// const moveById = (data, id, toId) => addById(findById(id, data), removeById(id, data), toId);

// https://gist.github.com/psandeepunni/80f5a62f0540b7989729
/* function flatArrObj(tree, keyChild, id){
	if(!tree[keyChild] || tree[keyChild].length === 0) return;
	
	let obj = {};
	for(var i=0; i < tree[keyChild].length; i++){
		let child = tree[keyChild][i]
		obj[child[id]] = child;
		bfs(child, keyChild, id);
	}
	return Object.entries(obj);
} */

/**
 *
 * @param arr
 * @param len
 * @returns {*[]}
 */
function chunk (arr, len) {
	let chunks = [],
		i = 0,
		n = arr.length;
	while (i < n) {
		chunks.push(arr.slice(i, i += len));
	}
	return chunks;
}

/**
 *
 * @param arr
 * @param key
 * @returns {*}
 */
function groupBy (arr, key) {
	return arr.reduce((res, item) => {
		(res[item[key]] = res[item[key]] || []).push(item);
		return res;
	}, {})
}

export { 
	arrMove, 
	arrMoveMutate, 
	addInto,
	chunk,
	groupBy, 
	cloneDeep, 
	// recursiveFind, 
	// recursiveFindIndex, 
	// recursiveEdit, 
	// sortDirBy, 
	// recursiveSortDir, 
	// findById, removeById, addById, moveById, 
};

