import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

import { Cx, hasClass, setAttr } from '../../utils/Q';

const Aroute = forwardRef(
	({
		to, 
		kind, 
		nav, 
		btn, 
		size, 
		outline, 
		dropdown, 
		listGroup, 
		noLine, 
		stretched, 
		className, 
		disabled, 
		role, 
		tabIndex, 
		noNewTab, 
		children, 
		onMouseEnter = () => {}, 
		onClick = () => {}, 
		onContextMenu = () => {},
		...etc
	}, 
	ref
) => {
	const MouseEnter = e => {		
		if(noNewTab){
			setAttr(e.target, "href");
		}

		onMouseEnter(e);
	}

	const Click = e => {
		const et = e.target;

    if(disabled){
      e.preventDefault();
      return;
    }
		// Close Dropdown menu if component in <Dropdown.Menu />
    if(hasClass(et, "dropdown-item")){
			document.body.click();
		}

		onClick(e);
	}

	const CtxMenu = e => {
		if(disabled){
			e.preventDefault();
			return;
		}
		onContextMenu(e);
	}

	return (
		<NavLink 
			{...etc} 
			ref={ref} 
			to={to} 	
			onMouseEnter={MouseEnter} 
			onClick={Click}
			onContextMenu={CtxMenu}

			className={
				Cx(`${btn ? "btn btn" : ""}${btn && outline ? "-outline" : ""}${btn ? "-" + btn : ""}`, {
					"nav-link" : nav, 
					["btn-" + size] : btn && size, 
					["text-" + kind] : kind, 
					"text-decoration-none": noLine, 
					"stretched-link": stretched, 
					"dropdown-item" : dropdown, 
					"list-group-item list-group-item-action": listGroup,
					"disabled" : disabled, 
					"route": !btn && !nav && !noLine && !dropdown && noNewTab
				}, className)
			}
			role={btn && !role ? "button" : role} 
			tabIndex={disabled && !tabIndex ? "-1" : tabIndex} 
			aria-disabled={disabled ? true : null}
		>
			{children}
		</NavLink>
	);
});

export default Aroute;
