import useWebSocket, { ReadyState } from 'react-use-websocket';
import { shallowEqual, useSelector } from "react-redux";
import { useEffect } from 'react';
import { parseJsonString } from '../json';
import { useState } from 'react';

const useRefresh = () => {
    const { uid } = useSelector(
		({ auth }) => ({
			uid: auth?.config?.userInfo?.uid,
		}),
		shallowEqual
	);
    const [lastValidMessage, setLastValidMessage] = useState({})

    const { sendMessage, lastMessage, readyState } = useWebSocket(`wss://${window.location.host}/TaskHub`, {
        queryParams: {
            userId: uid
        },
        shouldReconnect: (closeEvent) => true,
        reconnectAttempts: 10,
        reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    })

    useEffect(() => {
        if(uid && readyState === ReadyState.OPEN){
            sendMessage(`{"protocol":"json","version":1}\u001E`)
            sendMessage(`{"arguments":[],"invocationId":"0","streamIds":[],"target":"GetConnectionId","type":1}\u001E`)
        }
    }, [uid, readyState])

    useEffect(() => {
        if(lastMessage && lastMessage.data){
            let dataString = lastMessage.data.replace('\u001e', '')
            let newDataJson = parseJsonString(dataString)
            if(newDataJson.type !== 6){
                setLastValidMessage(newDataJson)
            }
        }
    }, [lastMessage])
    
    return lastValidMessage
}

export default useRefresh