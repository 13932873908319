const workflowEndpoints = {
    getShareSettings(params) {
        return {
            url: '/api/workflow/share',
            method: 'GET',
            params,
        }
    },
    getAssignee(params) {
        return {
            url: '/api/form/Editor/LookupAssignee',
            method: 'GET',
            params,
        }
    },
    getAssigneeByRecord(params) {
        return {
            url: '/api/form/Editor/LookupAssigneeByRecord',
            method: 'GET',
            params,
        }
    },
    getPermissibleFields(params) {
        return {
            url: '/api/workflow/permissible-fields',
            method: 'GET',
            params,
        }
    },
}

export default workflowEndpoints