import * as appMenuService from "../service/index.js";
import { appMenuSlice, callTypes } from "./applicationMenuSlice";
import _message from "@utils/message";
import { FolderOutlined, FileOutlined } from '@ant-design/icons';

const { actions } = appMenuSlice;
const mapTreeNodes = (nodes, isMenu) =>{
  return nodes.map((v) => {
    return {
    ...v,
    title : v.text,
    name : v.text,
    key : v.id,
    icon: isMenu ? v.icon : v.type !== "Module"? <FolderOutlined/> : <FileOutlined/>,
    categoryIcon : v.icon,
    description : v.description,
    children: mapTreeNodes(v.children, isMenu)
  }});
}
export const fetchTrees = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return appMenuService
    .getTree()
    .then(({ data }) => {
      dispatch(actions.treesFetched({ data:mapTreeNodes(data) }));
    })
    .catch((error) => {
      dispatch(actions.onSnackbarOpen({ message: error.message, type: "error" }));
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchMenus = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.menu }));
  return appMenuService
    .getMenu()
    .then(({ status, data }) => {
      switch (status) {
        case 200:
          dispatch(actions.menusFetched({ data:mapTreeNodes(data, 'menu') }));
          break;
        case 401:
          let error = "401: Unauthorized";
          dispatch(actions.catchError({ error, callType: callTypes.list }));
          break;
        default:
          break;
      }
    })
    .catch((error) => {
      dispatch(actions.onSnackbarOpen({ message: error.message, type: "error" }));
      dispatch(actions.catchError({ error, callType: callTypes.menu }));
    });
};

export const controlMenu = path => dispatch => {
  dispatch(actions.controlMenu({ path }));
}

export const unControlMenu = () => dispatch => {
  dispatch(actions.unControlMenu());
}

export const fetchDetail = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return appMenuService
    .getDetail(id)
    .then(({ data }) => {
      if (data.success) {
        const { data: detail } = data;
        dispatch(actions.detailFetched({
          data: detail
        }));
      } else {
        const error = data.message;
        dispatch(actions.onSnackbarOpen({ message: `ERROR: ${error}` ?? "Unexpected error", type: 'error' }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't load data";
      dispatch(actions.onSnackbarOpen({ message: `ERROR: ${error.message}` ?? "Unexpected error", type: 'error' }));
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const deleteMenu = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return appMenuService
    .deleteMenu(id)
    .then(response => {
      const { success, message } = response?.data;
      if (success) {
        _message.success(message ?? "Group has been deleted");
      } else {
        _message.error(message ?? "Failed to delete group");
      }
      dispatch(actions.clearState());
    })
    .catch(error => {
      _message.error(error.message ?? "Failed to delete group");
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const orderIndex = data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return appMenuService
    .orderIndex(data)
    .then(response => {
      const { success, message } = response?.data;
      if (!success) {
        _message.error(message ?? "Failed to order App Group");
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      } else {
        _message.success(message ?? "App group order saved");
        dispatch(fetchTrees());
        dispatch(fetchMenus());
      }
    })
    .catch(error => {
      error.clientMessage = "Can't order index";
      dispatch(actions.onSnackbarOpen({ message: `ERROR: ${error.message}`, type: 'error' }));
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const saveMenu = menu => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return appMenuService
    .saveMenu(menu)
    .then(response => {
      const { success, message } = response?.data;
      if (!success) {
        _message.error(message ?? "Failed to save group");
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      } else {
        _message.success(message ?? "Group has been saved");
        dispatch(fetchTrees());
        dispatch(fetchMenus());
      }
      dispatch(actions.clearState());
    })
    .catch(error => {
      _message.error(message ?? "Failed to save group");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const onSnackbarClose = () => dispatch => {
  dispatch(actions.onSnackbarClose());
  return;
}

export const clear = () => dispatch => {
  dispatch(actions.clearState());
  return;
}