export const strToSnakeCase = (str = '') => {
    return str.replace(/\s/g, '_').toLowerCase();
}

export const normalizeJsonString = str => {
    return str.replace(/\\+(?=")/g, '\\')
}

export default {
    strToSnakeCase, 
    normalizeJsonString,
}

