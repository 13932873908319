const userPreferenceEndpoints = {
    getUserInfo() {
        return {
            url: '/api/identity/manageauthenticator/userinfo',
            method: 'GET'
        }
    },
    getQuickLaunch() {
        return {
            url: '/api/form/Editor/userpreference/get/quicklaunch',
        }
    },
    setQuickLaunch({data}) {
        return {
            url: '/api/form/Editor/userpreference/set/quicklaunch',
            method: 'PUT',
            data
        }
    },
    getQuickLaunchApps() {
        return {
            url: '/api/form/Editor/quicklaunchapps/datatables',
            method: 'POST',
            data: {columns:[]}
        }
    }
}

export default userPreferenceEndpoints;