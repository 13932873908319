const taskEndpoints = {
    getModuleTasks({ moduleId, data }) {
        return {
            url: '/api/Task/datatables',
            method: 'POST',
            params: {
                moduleId,
            },
            data,
        }
    },
    getTasksCount() {
        return {
            url: '/api/Task/total',
            method: 'GET'
        }
    },
    getModuleTasksDynamic({ moduleId, data }) {
        return {
            url: '/api/Task/dynamicdatatables',
            method: 'POST',
            params: {
                moduleId,
            },
            data,
        }
    },
    getTasksByUser({ userId, data }) {
        return {
            url: '/api/Task/dataTablesByUserId',
            method: 'POST',
            params: {
                userId,
            },
            data
        }
    },
    getTasksByTeam({ teamId, data }) {
        return {
            url: '/api/Task/dataTablesByTeamId',
            method: 'POST',
            params: {
                teamId,
            },
            data
        }
    },
    getTaskDetails({ taskId }) {
        return {
            url: `/api/Task/${taskId}/detail`,
            method: 'GET',
        }
    },
}

export default taskEndpoints