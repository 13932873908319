import React from 'react';
import { Helmet } from 'react-helmet';

import { APP_NAME } from '../../data/appData';// ../data/appData

export default function Head({ title, children }){
  return (
    <Helmet 
      // (optional) used as a fallback when a template exists but a title is not defined
      defaultTitle={APP_NAME} // 
      titleTemplate={"%s | " + APP_NAME}
    >
      {title && <title>{title}</title>}

      {children}
    </Helmet>
  )
}
