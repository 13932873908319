const dataConnectorEndpoints = {
  saveSecurityHeader({ data }) {
      return {
          url: '/api/dataconnector/securityheader/save',
          method: 'POST',
          data,
      }
  },
  fetchSecurityHeader() {
      return {
          url: `/api/dataconnector/securityheader/fetch`,
          method: 'GET',
      }
  },
  postSecurityHeaderDatatables({ data }) {
      return {
          url: '/api/dataconnector/securityheader/datatables',
          method: 'POST',
          data,
      }
  },
  fetchDataConnectorById({ recordId }) {
      return {
          url: `/api/dataconnector/${recordId}/detail`,
          method: 'GET',
      }
  },
  saveDataConnector({ data }) {
      return {
          url: '/api/dataconnector/save',
          method: 'POST',
          data
      }
  },
  duplicateDataConnector({ data }) {
    return {
        url: '/api/dataconnector/copyfrom',
        method: 'POST',
        data
    }
  },
  fetchDataConnectors() {
      return {
          url: '/api/dataconnector/fetch',
          method: 'GET',
      }
  },
  fetchDataConnectorDatatables({ data }) {
      return {
          url: '/api/dataconnector/datatables',
          method: 'POST',
          data,
      }
  },
  deleteDataConnectorsByIds({ data }) {
      return {
          url: '/api/dataconnector/deletebyids',
          method: 'POST',
          data
      }
  },
  deleteDataConnectorById({ recordId: id }) {
      return {
          url: '/api/dataconnector/deletebyid',
          method: 'POST',
          params: {
              id
          },
      }
  },
  runDataConnector({ id: dataConnectorId, data = {} }) {
      return {
          url: `/api/workflowapi/rundataconnector`,
          method: 'POST',
          params: {
              dataConnectorId,
          },
          data,
      }
  },
  dataConnectorExecute({ id: dataConnectorId, data = {} }) {
      return {
          url: `/api/workflowapi/dataconnectorexecute`,
          method: 'POST',
          params: {
              dataConnectorId,
          },
          data,
      }
  },
}

export default dataConnectorEndpoints;