/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
*/
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
// import 'antd/dist/antd.css'; // MOVED TO _metronic/_assets/sass/finansys
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
// import "@fortawesome/fontawesome-free/css/all.min.css"; // MOVED TO _metronic/_assets/sass/finansys
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
// import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";

// FS-CUSTOM:
import { isMobile, setClass } from './utils/Q';// makeEl, 
// import getScript from './utils/load/getScript';
import Honeybadger from "@honeybadger-io/js";
import { HoneybadgerErrorBoundary as ErrorBoundary } from "@honeybadger-io/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

Honeybadger.configure({
  apiKey: 'hbp_3f8b86HDFYGjKdbG4YwWApQ2lHu4J43OndQ9',
  environment: process.env.NODE_ENV || 'production',
  breadcrumbsEnabled: {
    dom: true,
    network: true,
    navigation: true,
    console: process.env.REACT_APP_HB_IN_CONSOLE,
  }
})

window.URL = window.URL || window.webkitURL;

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
*/
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
*/
_redux.setupAxios(axios, store);

// FS-CUSTOM: Add SUPPORT focus preventScroll
// let focusOptions = false;
// makeEl('div').focus({
//   get preventScroll() {
//     focusOptions = true;
//     return false;
//   }
// });
// if(!focusOptions){
//   getScript({ src: "/js/libs/polyfill/focus-options.js" }).then(v => console.log(v)).catch(e => console.warn(e));
// }

// Detect mobile device:
isMobile() && setClass(document.body, "isMobile");

ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <ErrorBoundary honeybadger={Honeybadger}>
            <App store={store} persistor={persistor} basename={PUBLIC_URL} />
          </ErrorBoundary>
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();