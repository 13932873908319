import React from 'react';

import darkOrLight from '../../utils/darkOrLight';
import { isNum, Cx, hasClass, setClass, setAttr, getInitials } from '../../utils/Q';

// FROM: chakra-ui
function str2Hex(str, no = "5a6268") {
	if (!str || str.length < 1) return no;

	let hash = 0,
			sl = str.length;

	for (let i = 0; i < sl; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
		hash = hash & hash;
	}

	let color = "";// #
	for (let j = 0; j < 3; j++) {
		let val = (hash >> (j * 8)) & 255;
		color += ("00" + val.toString(16)).substr(-2);
	}
	return color;
}

export default function Ava({
	w = 30,
	h = 30, 
	alt = " ",
	loading = "lazy",
	drag = false,
	className, 
	frameClass, 
	frameProps, 
	bg, // Define Error background-color
	labelColor, // DEFINE text color
	circle, 
	rounded, 
	contain,
	onError, 
	onLoad, 
	children, // OPTION
	...etc
}){
	const LOAD_CX = "ant-skeleton-avatar";
	const removeLoader = (et) => setClass(et, LOAD_CX, "remove");

	const Err = e => {
		let et = e.target;

		removeLoader(et);

		if (hasClass(et, "text-dark")) setClass(et, "text-dark", "remove");
		if (hasClass(et, "text-white")) setClass(et, "text-white", "remove");

		let fs = '--fs:calc(';
		if (isNum(w)) {
			fs += w + "px / 2.25);";
		} else {
			let num = parseFloat(w);
			let unit = w.replace("" + num, "");
			fs += num + unit + " / 2.25);";
		}

		let trm = alt ? alt.trim() : "N";
		let bgColor = bg ? bg.replace("#", "") : str2Hex(trm);

		setAttr(et, {
			"aria-label": getInitials(trm), 
			style: fs + "--bg:#" + bgColor
		});

		setClass(et, labelColor ? labelColor : darkOrLight(bgColor) === "dark" ? "text-white" : "text-dark");

		if (onError) onError(e);

		// DEV OPTION: Change src with svg
		// et.src = `data:image/svg+xml,%3Csvg width="100%25" height="100%25" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice"%3E%3Cstyle%3E%0Asvg%7Bfont-family:sans-serif;font-size:1rem;text-anchor:middle%7D%0A%3C/style%3E%3Crect width="100%25" height="100%25" fill="%2355595c"%3E%3C/rect%3E%3Ctext x="50%25" y="50%25" fill="%23eceeef" dy=".35em"%3E${getInitials(alt)}%3C/text%3E%3C/svg%3E`;

		// FROM MDN https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror = return null;
		return;
	}

	const Load = e => {
		removeLoader(e.target);
		if (onLoad) onLoad(e);
	}

	return (
		<div 
			{...frameProps}
			className={
				Cx("img-frame ava-frame ant-skeleton ant-skeleton-active", {
					"rounded-circle": circle, 
				}, frameClass)
			}
		>
			<img
				{...etc}
				loading={loading} 
				width={w}
				height={h} 
				alt={alt}
				className={
					Cx("ava " + LOAD_CX, {
						"rounded-circle": circle,
						"rounded": rounded,
						"of-con": contain
					}, className)
				}
				draggable={drag} 
				onError={Err}
				onLoad={Load}
			/>

			{children}
		</div>
	);
}
