import React, { useRef, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import List from '@researchgate/react-intersection-list';
import Flex from './Flex';
import Img from './Img';
import { num2Hex } from '@utils/number';
import { Cx } from '@utils/Q';

const parseObjPath = (path) => ({ path, name: path?.split("/")?.pop() });

export default function IconPicker({
    icons, // Object data 
    url, // String url fetch
    type = null,
    storageKey = "icons",
    cache = true, // DEV OPTION: Caching used
    autoFocus = false,
    autoClose = true,
    preview = {
        name: "home",
        code: "e933"
    },
    pageSize = 50,
    onClickIcon = () => { }, // Q.noop
    onHoverIcon = () => { },
}) {
    const findRef = useRef(null);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState(icons);
    const [meta, setMeta] = useState(null);
    const [show, setShow] = useState(false);
    const [view, setView] = useState(preview);
    const [findVal, setFindVal] = useState("");
    const onHover = (v, e) => {
        const valCondition = type === "font" ? v?.name : v
        const toView = meta ? v : parseObjPath(valCondition); // { path: v, name: v.split("/").pop() }
        if (preview && view.name !== v.name) {
            setView(toView);
        }

        onHoverIcon(toView, e);
    }

    const onClick = (icon) => {
        onClickIcon(icon); // { path: v, name: v.split("/").pop() }

        if (autoClose) { // OPTION: Close Dropdown.Menu
            setShow(false);

            if (findVal.length > 0) { // Reset find
                setFindVal("");
            }
        }
    }

    const onFind = (e) => {
        const val = e.target.value || '';
        setFindVal(val);
    }

    const getFilteredData = d => {
        const iconName = (d?.name || '').toLowerCase()
        return iconName.match(findVal.toLowerCase())
    }

    const filteredData = [...(data || [])].filter(getFilteredData)

    const itemsRenderer = (items, ref) => {
        const notFound = findVal.length > 0 && filteredData.length === 0;
        return (
            <Flex wrap // 
                ref={ref} // iconBoxRef 
                className={Cx("position-absolute position-full p-1 border bw-y1 ovyauto icons-box", { "icoNotFound": notFound })}
                data-notfound="NOT FOUND"
            >
                <Flex wrap content="start">
                    {notFound ? [] : items}
                </Flex>
            </Flex>
        );
    }

    const renderItem = (index, key) => {
        const list = filteredData
        const v = list[index];
        const title = "name: " + v.name + (v.code ? "\ncode: " + v.code : "");
        if (type === "font") {
            return (
                <Button key={key} size="lg" variant="outline"
                    onMouseEnter={e => onHover(v, e)}
                    onClick={e => onClick({ icon: { ...v, className: pref() + " " + v.prefix + " " + v.name }, key }, e)}
                    className={"m-1 q-fw " + pref() + " " + v.prefix + " " + v.name}
                    title={title}
                    style={{fontSize:'20px'}}
                />
            );
        } else if (meta) {
            return (
                <Button key={key} size="lg" variant="outline"
                    onMouseEnter={e => onHover(v, e)}
                    onClick={e => onClick({ icon: { ...v, className: pref() + meta.prefix + v.name }, key }, e)}
                    className={"m-1 q-fw " + pref() + meta.prefix + v.name}
                    title={title}
                />
            );
        } else {
            let filename = v.split("/").pop();
            return (
                <Img key={key}
                    frame
                    frameClass="dropdown-item icon-item p-1 flex0 m-1"
                    wrapProps={{
                        title: filename,
                        onMouseEnter: e => onHover(v, e),
                        onClick: e => onClick(v, e)
                    }}
                    alt={filename}
                    src={v}
                />
            );
        }
    }

    const onClearFind = () => {
        if (findVal.length > 0) {
            setFindVal("");
        }
    }

    const pref = () => meta && meta?.prefix?.replace("-", " ");

    useEffect(() => {
        if (show) { // cacheIcon || 
            fetchIcon();
        } else {
            setLoad(true);
        }
        // eslint-disable-next-line
    }, [show]);// , onGetSetData

    const fetchIcon = () => { // cb
        if (typeof url === "string") {
            axios.get(url).then(r => {
                if (r.status === 200) { // CHECK TO...???
                    let data = r.data;
                    let dataIcon, metas;

                    if (data.type === "svg") {
                        dataIcon = data.icons;
                        // const ico = dataIcon[0];
                        setView(parseObjPath(dataIcon[0])); // { path: ico, name: ico.split("/").pop() }
                    } else {
                        metas = {
                            name: data.metadata.name,
                            prefix: data.preferences.fontPref.prefix
                        };

                        dataIcon = data.icons.map(v => ({
                            name: v.properties.name,
                            code: num2Hex(v.properties.code)
                        }));

                        setMeta(metas);
                    }

                    if (cache) {
                        const objStorage = metas ? { meta: metas, data: dataIcon } : data;
                        localStorage.setItem(storageKey, JSON.stringify(objStorage));
                    }

                    setLoad(true);
                    setData(dataIcon);
                }
            })
                .catch(e => console.log(e))
            // .then(() => setLoad(true));
        } else { //is object || not string
            let dataCond = Object.prototype.toString.call(url) === '[object Object]' && JSON.stringify(url) !== '{}', dataIcon = [];
            if (dataCond) {
                try {
                    dataIcon = url?.icons || []
                    dataIcon = dataIcon?.length && dataIcon?.map(v => ({
                        name: v.name,
                        prefix: v.prefix
                    }));
                    if (cache) {
                        // const objStorage = metas ? { meta: metas, data: dataIcon } : data;
                        const objStorage = dataIcon;
                        localStorage.setItem(storageKey, JSON.stringify(objStorage));
                    }


                } catch (error) {

                }
                setLoad(true);
                setData(dataIcon);
            }
        }
    }

    return (
        <>
            <Button variant="secondary" size="sm" className={"fa-fw far fa-search"} onClick={() => setShow(true)} />
            <Modal
                centered
                scrollable
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="modal-application-menu"
            >
                <Modal.Header closeButton className="py-3">
                    <Modal.Title id="modal-application-menu">Select Icon</Modal.Title>
                </Modal.Header>
                <div className="modal-body py-4 ovxauto">
                    {(data && load) ?
                        <Flex dir="column" align="stretch"
                            style={{ height: 330 }}
                        >
                            <label className="d-flex m-0 p-1 flexno bg-strip">
                                <input ref={findRef}
                                    autoFocus={autoFocus}
                                    value={findVal}
                                    onChange={onFind}
                                    className="form-control form-control-sm"
                                    type="search" // text | search
                                    placeholder="Search"
                                />

                                <Button as="b" size="sm" variant="light"
                                    className={"fa-fw far fa-" + (findVal.length > 0 ? "times xx" : "search")}
                                    onClick={onClearFind}
                                />
                            </label>

                            <Flex dir="column" align="justify" className="flex1 h-100 position-relative wrap-icons">
                                <List
                                    awaitMore={filteredData > 0}
                                    pageSize={pageSize} // 70
                                    itemCount={filteredData.length} //  
                                    itemsRenderer={itemsRenderer}
                                    renderItem={renderItem}
                                />
                            </Flex>
                        </Flex>
                        :
                        <Flex dir="column" align="center"
                            className={"text-center p-5 " + (load ? "cwait" : "fa fa-ban ired fa-2x")} // i-load 
                        >
                            {load ? "LOADING" : "ICONS NOT LOAD"}
                        </Flex>
                    }
                </div>
            </Modal>
        </>
    );
}
