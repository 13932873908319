import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { loginWith2Fa, getInfo2fa } from "../_redux/authCrud";

function MfaPage(props) {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        getInfo2fa().then(e => {
            if (e) {
                const { data } = e.data;
                setUser(data);
            }
        }).catch(e => console.log(e));
    }, [setUser]);

    const formik = useFormik({
        initialValues: { twoFactorCode: "", rememberMachine: false },
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setStatus("");
            loginWith2Fa({ ...values, isPersistent: true }).then(res => {
                const { success, message } = res.data;
                if (!success) {
                    setStatus(message);
                } else {
                    setTimeout(() => {
                        props.loginMfa();
                    }, 1000);
                }
                disableLoading();
                setSubmitting(false);
            }).catch(e => {
                setStatus(e.message);
                disableLoading();
                setSubmitting(false);
            });
        }
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    return (
        <div className="login-form login-sigin">
            <div className="card shadow-sm">
                <div className="card-body">
                    <h1 className="h3 mt-2 text-primary text-center hr-h">MFA Code</h1>
                    <h5 className="text-center mb-10 my-4">Your login is protected with an authenticator app.<br />Enter your authenticator code below.</h5>

                    <form
                        onSubmit={formik.handleSubmit}
                        className="col mx-auto"
                    >
                        <fieldset>
                            {formik.status && <div className="alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">{formik.status}</div>
                            </div>}
                            <label>Username: {user && user.name}</label>
                            <div className="form-group">
                                <input
                                    type="search" autoComplete="off"
                                    className="form-control" 
                                    name="twoFactorCode"
                                    {...formik.getFieldProps("twoFactorCode")}
                                />
                                {formik.touched.twoFactorCode && formik.errors.twoFactorCode ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.twoFactorCode}</div>
                                    </div>
                                ) : null}
                            </div>
                            <button type="submit" disabled={formik.isSubmitting}
                                className="font-weight-bold btn btn-primary btn-block"><span>Sign In</span>
                                {loading && <span className="ml-3 spinner spinner-white"></span>}</button>
                            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                <Form.Check type="checkbox" disabled={formik.isSubmitting} name="rememberMachine" label="Remember Machine" onChange={formik.handleChange} />
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default connect(null, auth.actions)(MfaPage);