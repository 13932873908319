const datasetEndpoints = {
    getDatasetList(params) {
        return {
            url: '/api/ReferenceData/ListDataset',
            method: 'GET',
            params,
        }
    },
    getDataByKey(params) {
        return {
            url: '/api/ReferenceDataEntry/GetDataByKey',
            method: 'GET',
            params,
        }
    },
    getDatasetTable({ data = {}}) {
        return {
            url: '/api/ReferenceData/datatables',
            method: 'POST',
            data,
        }
    },
    getDataset(params) {
        return {
            url: '/api/ReferenceData/getDataset',
            method: 'GET',
            params
        }
    },
    addEntry({referencedataId, data}) {
        return {
            url: `/api/ReferenceDataEntry/AddRow?referencedataId=${referencedataId}&totalRow=1`,
            method: 'POST',
            data
        }
    },
    saveRefDataEntry({referencedataId, data}) {
        return {
            url: '/api/ReferenceDataEntry/batch?referencedataId='+referencedataId,
            method: 'POST',
            data
        }
    }
}

export default datasetEndpoints