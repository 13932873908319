const optionsEndpoints = {
    getDatasetFields(params) {
        return {
            url: '/api/permissible-list/dataset',
            method: 'GET',
            params,
        }
    },
}

export default optionsEndpoints