import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Redirect } from "react-router-dom";
import { useInvalidateAllModuleDatatableQueries } from "../../../../utils/states/NewDataTable.state";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { actions } from "../_redux/authRedux";

const Logout = () => {
  const refreshDatatable = useInvalidateAllModuleDatatableQueries()
  const dispatch = useDispatch()
  const { hasAuthToken } = useSelector(
    ({ auth }) => ({
      hasAuthToken: Boolean(auth.authToken),
    }),
    shallowEqual
  );

  const doLogout = async () => {
    await refreshDatatable()
    dispatch(actions.logout())
  }

  useEffect(() => {
    doLogout()
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return hasAuthToken 
    ? <LayoutSplashScreen /> 
    : <Redirect to="/authentication/login" />
}

export default Logout
