import { permissionCodes } from "@components/QueryBuilder/adapter";

export const findByName = field => obj => {
    return obj.name === field.name
}

export const findByValueActivityId = rule => obj => {
    return obj.value === rule.activityId
}
export const isSection = ({ name, sections }) => {
    return sections.findIndex(s => s.name === name) > -1;
}

export const rawToRules = ({ raw, rules, sections }) => {
    return rules.map(r => {
        const rule = {...r}
        const rawEntries = Object.entries(raw)
        const mapControl = entry => {
            const [name, config] = entry
            const { editability, visibility } = config
            const { defaultVisibility, root: rootVisibility } = visibility || {};
            const { defaultEditability, root: rootEditability } = editability || {};
            const visibilityControls = ['visible', 'hidden']
            const editabilityControls = ['editable', 'readonly']
            const controls = [
                defaultVisibility,
                defaultEditability,
            ]
            const rootPredicate = findByValueActivityId(rule)
            if (rootVisibility?.rules.findIndex(rootPredicate) !== -1) {
                controls.splice(controls.findIndex(c => c === defaultVisibility), 1)
                controls.push(visibilityControls.find(c => c !== defaultVisibility))
            }
            if (rootEditability?.rules.findIndex(rootPredicate) !== -1) {
                controls.splice(controls.findIndex(c => c === defaultEditability), 1)
                controls.push(editabilityControls.find(c => c !== defaultEditability))
            }

            return {
                name,
                control: Math.max(
                    ...controls.map(c => {
                        return permissionCodes.findIndex(code => code === c)
                    })
                ),
            }
        }

        rule.fields = rawEntries.filter(([name]) => {
            return !isSection({ name, sections })
        }).map(mapControl)

        rule.fieldGroups = rawEntries.filter(([name]) => {
            return isSection({ name, sections })
        }).map(mapControl)

        return rule
    })
}

export const setDefaultPermission = ({ permission, sections }) => {
    let result = {...permission}
    result.rules = rawToRules({ 
        raw: permission.rawPermission, 
        rules: permission.rules, 
        sections,
    })
    return result
}