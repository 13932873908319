import axios from "axios";

export const AUTH_URL = "/api/identity/Authentication";

export function login(values) {
  return axios.post(`${AUTH_URL}/authenticateiso`, values, { headers: {'Content-Type': 'application/json-patch+json'}});
}

export function loginWith2Fa(values) {
  return axios.post(`${AUTH_URL}/loginWith2Fa`, values);
}

export function isAuthenticated() {
  return axios.get(`${AUTH_URL}/isAuthenticated`);
}

export function getInfo2fa() {
  return axios.get(`${AUTH_URL}/getInfo2fa`);
}

export function requestPassword(email, type) {
  return axios.get('/api/email/requestpassword', { params: { email, type } });
}

export function forgotPassword(username) {
  return axios.get('/api/email/forgotpassword', { params: { username }});
}

export function validationToken() {
  return axios.post(`${AUTH_URL}/tokenValidation`);
}

export function refreshToken(currentToken) {
  return axios.post(`${AUTH_URL}/refreshToken`, { jwt: currentToken});
}
