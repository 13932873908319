/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
// import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { shallowEqual, useSelector } from "react-redux";
import { Skeleton } from "antd";
import axios from "axios";

import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import Aroute from '../../../../../app/components/Aroute';
import { Link } from "react-router-dom";
import useGetNotification from '@pages/NotificationPage/services';

// const perfectScrollbarOptions = {
//   wheelSpeed: 2,
//   wheelPropagation: false,
// };

export function UserNotificationsDropdown() {
    const [load, setLoad] = useState(false);
    const [notifs, setNotifs] = useState(null);
    const uiService = useHtmlClassService();
    const { onDetail } = useGetNotification();
    const layoutProps = useMemo(() => {
        return {
            offcanvas: objectPath.get(uiService.config, "extras.notifications.layout") === "offcanvas",
        };
    }, [uiService]);

    const { uid } = useSelector(
        ({ auth }) => ({
            uid: auth?.config?.userInfo?.uid,
        }),
        shallowEqual
    );

    const loadNotifs = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoad(false);
        axios.get("/api/Notification", { cancelToken: source.token })
            .then(res => {
                const { success, data } = res.data;
                // console.log('data: ', data);
                if (success && data) {
                    setNotifs(data);
                }
            }).catch(e => {
                // console.log('catch e: ', e);
                if (axios.isCancel(e)) {
                    console.log('Request canceled: ', e.message);
                }
            })
            .then(() => setLoad(true));

        return () => {
            source.cancel();// cancel the request (the message parameter is optional)
        }
    }
    useEffect(() => {
        loadNotifs();
    }, []); // pageFocus, once

    const onToggle = (open) => {
        if (open) {
            loadNotifs();
        }
    }

    return (
        <>
            {layoutProps.offcanvas && (
                <div className="topbar-item">
                    <div
                        className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                        id="kt_quick_notifications_toggle"
                    >
                        <span className="svg-icon svg-icon-xl svg-icon-primary">
                            <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
                        </span>
                        <span className="pulse-ring" />
                    </div>
                </div>
            )}

            {!layoutProps.offcanvas && (
                <Dropdown onToggle={onToggle} alignRight drop="down">
                    <Dropdown.Toggle
                        as={DropdownTopbarItemToggler}
                        id="kt_quick_notifications_toggle"
                    >
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="user-notification-tooltip">Notifications</Tooltip>
                            }
                        >
                            <div
                                className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1 pulse pulse-primary"
                                id="kt_quick_notifications_toggle"
                            >
                                <span className="svg-icon svg-icon-xl svg-icon-primary fa-lg fi-primary far fa-bell position-relative">
                                    {/* <i className="fi-primary far fa-bell" /> */}
                                    {(notifs?.unread > 0 && !notifs.open) && <b className="badge badge-danger badge-notif position-absolute zi-2">{notifs.unread}</b>}
                                </span>

                                {(notifs?.unread > 0 && !notifs.open) && <span className="pulse-ring" />}
                            </div>
                        </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                        <form>
                            <div className="d-flex flex-column align-items-center justify-content-center py-7 bg-gradient-main rounded-top">
                                <h3 className="text-white font-weight-bold mb-0">Notifications</h3>
                            </div>

                            <div
                                id="scrollableDiv"
                                className="navi navi-hover min-h-150px ovyauto ovscroll-contain fs-scroll border-top-next fs-notif-list" // py-3 my-4 position-relative
                                style={{ maxHeight: 300 }}
                            >
                                {load && notifs ?
                                    notifs.notif.length > 0 ?
                                        (notifs.notif).map((v, i) => {
                                            const isStartProcess = v.current_step === "StartProcess" || v.notification_type_name === "restart";
                                            const editorPage = v.task_id && !isStartProcess ? 'task-editor' : 'item-editor';
                                            const routeTo = v.module_id && v.record_id ?
                                                `/${editorPage}/${v.module_id}/${v.record_id}/${v.task_id ?? ''}`
                                                : `/notifications/${v.id}`;
                                            const sinceString = v.time_since ?? '';
                                            const moreThan1daySince = sinceString.includes('days') && sinceString.split(' ')[0] > 1;

                                            return (
                                                <Aroute key={i}
                                                    to={routeTo}
                                                    className={"navi-item" + (v.read_on ? "" : " unread")}
                                                    onClick={() => {
                                                        onDetail(v)
                                                    }}
                                                >
                                                    <div className="navi-link align-items-start">
                                                        <div className="navi-icon mr-3">
                                                            <i className="fa fa-user-circle fa-2x" />
                                                        </div>
                                                        <div className="navi-text">
                                                            <p style={{ fontWeight: 700, fontSize: 14, marginBottom: 0 }}>{v.record_created_by}</p>
                                                            {v.notification_content && <div>{v.notification_content}</div>}
                                                            {v.time_since && <time className={`${moreThan1daySince && !v.read_on ? "text-danger" : "text-muted"} q-mr fal fa-clock`} dateTime={v.created_on}>{v.time_since}</time>}
                                                        </div>
                                                    </div>
                                                </Aroute>
                                            )
                                        }
                                        )
                                        :
                                        <b className="d-block p-5 text-center">No new notifications</b>
                                    :
                                    <div className="py-3 px-5 mt-3-next">
                                        {[1, 2, 3].map((v) =>
                                            <Skeleton key={v} avatar active title={false} paragraph={{ rows: 2, width: "100%" }} />
                                        )}
                                    </div>
                                }
                            </div>

                            <div className="py-3 pr-3 pl-5 border-top text-center">{/* d-flex align-items-center */}
                                <Link to="/notifications">Show all</Link>
                                {/* <Aroute to="/app/user/profile" btn="flat" size="sm" className="ml-auto fa-lg far fa-cog" /> */}
                            </div>
                        </form>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
}

/*
{load ? 
    notifs ? 
        (notifs.notif || []).map((v, i) => 
            <Aroute key={i} 
                to={"/notifitions/" + v.id} 
                className="navi-item" 
            >
                <div className="navi-link align-items-start">
                    <div className="navi-icon mr-3">
                        <i className="fa fa-user-circle fa-2x text-secondary" />
                    </div>
                    <div className="navi-text">
                        {v.notification_content && <div>{v.notification_content}</div>}
                        {v.time_since && <div className="text-muted q-mr fal fa-clock">{v.time_since}</div>}
                    </div>
                </div>
            </Aroute>
        )
        :
        <b>No new notifications</b>
    : 
    <div className="py-3 px-5 mt-3-next">
        {[1,2,3].map((v) => 
            <Skeleton key={v} avatar active title={false} paragraph={{ rows: 2, width: "100%" }} />
        )}
    </div>
}

<Tab.Container defaultActiveKey={key}>
    <Nav
        as="ul"
        className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        onSelect={(_key) => setKey(_key)}
    >
        <Nav.Item className="nav-item" as="li">
            <Nav.Link
                eventKey="Alerts"
                className={`nav-link show ${
                    key === "Alerts" ? "active" : ""
                }`}
            >
                Alerts
            </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
            <Nav.Link
                eventKey="Events"
                className={`nav-link show ${
                    key === "Events" ? "active" : ""
                }`}
            >
                Events
            </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
            <Nav.Link
                eventKey="Logs"
                className={`nav-link show ${
                    key === "Logs" ? "active" : ""
                }`}
            >
                Logs
            </Nav.Link>
        </Nav.Item>
    </Nav>

    <Tab.Content className="tab-content">
        <Tab.Pane eventKey="Alerts" className="p-8">
            <PerfectScrollbar
                options={perfectScrollbarOptions}
                className="scroll pr-7 mr-n7"
                style={{ maxHeight: "300px", position: "relative" }}
            >
                <div className="d-flex align-items-center mb-6">
                    <div className="symbol symbol-40 symbol-light-primary mr-5">
                        <span className="symbol-label">
                            <SVG title=" " 
                                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                                className="svg-icon-lg svg-icon-primary"
                            ></SVG>
                        </span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                        <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                            Briviba SaaS
                        </a>
                        <span className="text-muted">
                            PHP, SQLite, Artisan CLIмм
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center mb-6">
                    <div className="symbol symbol-40 symbol-light-warning mr-5">
                        <span className="symbol-label">
                            <SVG title=" " 
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                className="svg-icon-lg svg-icon-warning"
                            ></SVG>
                        </span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                        <a
                            href="#"
                            className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                        >
                            Briviba SaaS
                        </a>
                        <span className="text-muted">
                            PHP, SQLite, Artisan CLIмм
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center mb-6">
                    <div className="symbol symbol-40 symbol-light-success mr-5">
                        <span className="symbol-label">
                            <SVG title=" " 
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")}
                                className="svg-icon-lg svg-icon-success"
                            ></SVG>
                        </span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                        <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                            Briviba SaaS
                        </a>
                        <span className="text-muted">
                            PHP, SQLite, Artisan CLIмм
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center mb-6">
                    <div className="symbol symbol-40 symbol-light-danger mr-5">
                        <span className="symbol-label">
                            <SVG title=" " 
                                src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}
                                className="svg-icon-lg svg-icon-danger"
                            ></SVG>
                        </span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                        <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                            Briviba SaaS
                        </a>
                        <span className="text-muted">
                            PHP, SQLite, Artisan CLIмм
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <div className="symbol symbol-40 symbol-light-info mr-5">
                        <span className="symbol-label">
                            <SVG title=" " 
                                src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}
                                className="svg-icon-lg svg-icon-info"
                            ></SVG>
                        </span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                        <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                            Briviba SaaS
                        </a>
                        <span className="text-muted">
                            PHP, SQLite, Artisan CLIмм
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <div className="symbol symbol-40 symbol-light-info mr-5">
                        <span className="symbol-label">
                            <SVG title=" " 
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Mail-notification.svg")}
                                className="svg-icon-lg svg-icon-info"
                            ></SVG>
                        </span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                        <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                            Briviba SaaS
                        </a>
                        <span className="text-muted">
                            PHP, SQLite, Artisan CLIмм
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                    <div className="symbol symbol-40 symbol-light-info mr-5">
                        <span className="symbol-label">
                            <SVG title=" " 
                                src={toAbsoluteUrl("/media/svg/icons/Design/Bucket.svg")}
                                className="svg-icon-lg svg-icon-info"
                            ></SVG>
                        </span>
                    </div>
                    <div className="d-flex flex-column font-weight-bold">
                        <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                        >
                            Briviba SaaS
                        </a>
                        <span className="text-muted">
                            PHP, SQLite, Artisan CLIмм
                        </span>
                    </div>
                </div>
            </PerfectScrollbar>
        </Tab.Pane>
        <Tab.Pane
            eventKey="Events"
            id="topbar_notifications_events"
        >
            <PerfectScrollbar 
                options={perfectScrollbarOptions}
                className="navi navi-hover scroll my-4"
                style={{ maxHeight: "300px", position: "relative" }}
            >
                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-line-chart text-success"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                New report has been received
                            </div>
                            <div className="text-muted">23 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-paper-plane text-danger"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                Finance report has been generated
                            </div>
                            <div className="text-muted">25 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-user flaticon2-line- text-success"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                New order has been received
                            </div>
                            <div className="text-muted">2 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-pin text-primary"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                New customer is registered
                            </div>
                            <div className="text-muted">3 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-sms text-danger"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                Application has been approved
                            </div>
                            <div className="text-muted">3 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-pie-chart-3 text-warning"></i>
                        </div>
                        <div className="navinavinavi-text">
                            <div className="font-weight-bold">
                                New file has been uploaded
                            </div>
                            <div className="text-muted">5 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon-pie-chart-1 text-info"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                New user feedback received
                            </div>
                            <div className="text-muted">8 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-settings text-success"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                System reboot has been successfully completed
                            </div>
                            <div className="text-muted">12 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon-safe-shield-protection text-primary"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                New order has been placed
                            </div>
                            <div className="text-muted">15 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-notification text-primary"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                Company meeting canceled
                            </div>
                            <div className="text-muted">19 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-fax text-success"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                New report has been received
                            </div>
                            <div className="text-muted">23 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon-download-1 text-danger"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                Finance report has been generated
                            </div>
                            <div className="text-muted">25 hrs ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon-security text-warning"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                New customer comment recieved
                            </div>
                            <div className="text-muted">2 days ago</div>
                        </div>
                    </div>
                </a>

                <a href="#" className="navi-item">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-analytics-1 text-success"></i>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">
                                New customer is registered
                            </div>
                            <div className="text-muted">3 days ago</div>
                        </div>
                    </div>
                </a>
            </PerfectScrollbar>
        </Tab.Pane>
        <Tab.Pane eventKey="Logs" id="topbar_notifications_logs">
            <div className="d-flex flex-center text-center text-muted min-h-200px">
                All caught up!
                <br />
                No new notifications.
            </div>
        </Tab.Pane>
    </Tab.Content>
</Tab.Container>
*/