import { useQueryClient } from "@tanstack/react-query";
import useRefresh from "@utils/hooks/useRefresh";
import { notification, Modal } from "antd";
import { useEffect, useState, useRef, useCallback } from "react";
import { actions } from "../modules/Auth/_redux/authRedux";
import { useJwt } from "react-jwt";
import moment from "moment";
import * as service from "../modules/Auth/_redux/authCrud";
import { useSelector, useDispatch } from "react-redux"; 
import { useHistory } from "react-router-dom";

const Refresher = ({ children }) => {
    const queryClient = useQueryClient()
	const history = useHistory();
    const message = useRefresh()
    const { authToken, authConfig } = useSelector(({auth}) => ({ authToken: auth?.authToken, authConfig: auth?.config }));
    const dispatch = useDispatch();
    const { decodedToken, reEvaluateToken } = useJwt(authToken ?? '');
    const [isInactive, setIsInactive] = useState(false)
    const events = ['click', 'keypress', 'scroll']
    const isInactiveRef = useRef(isInactive);
    const timerRef = useRef(0);
    const inactivityTimeout = useRef();
    const modalTimeout = useRef();

    const bc = new BroadcastChannel("auth_channel");
    bc.addEventListener("message", (event) => {
        const newToken = event.data;
        if(newToken){
            Modal.destroyAll()
            clearInterval(timerRef.current)
            reEvaluateToken(newToken)
        }
    });
    
    const startInactivityCheck = () => {
        isInactiveRef.current = true;
        setIsInactive(true)
    }
    const setActive = () => {
        isInactiveRef.current = false;
        setIsInactive(false)
        refreshToken()
    }

    useEffect(() => {
        if(isInactive){
            events.forEach(event => {
                window.addEventListener(event, setActive)
              });
        } else {
            events.forEach(event => {
                window.removeEventListener(event, setActive)
              });
        }
        return () => {
            events.forEach(event => {
                window.removeEventListener(event, setActive)
            });
        }
    }, [isInactive])

    const refreshToken = async () => {
        try{
            if(authToken && authConfig?.appMenu){
                clearInterval(timerRef.current)
                const response = await service.refreshToken(authToken);
                const { data } = response || {};
                const newToken = data.data.jwt
                bc.postMessage(newToken)
                dispatch(actions.refreshToken({authToken: newToken, config: authConfig}))
            } else {
                Modal.destroyAll()
                showModalAfterLogout("Failed to refresh access token")
                history.push("/logout")
            }
        } catch (e) {
            console.error("~ Refresher ~ refreshToken ~ error:", e)
        }
    }

    const showModalAlert = (expired) => {
        Modal.confirm({
            icon: null,
            maskClosable: false,
            closable: false,
            content: <Countdown eventTime={expired} interval={1000} timerRef={timerRef}/>,
            okText: "Continue session",
            cancelButtonProps:{style:{display: 'none'}},
            title: "Your session is expiring soon",
            onOk: refreshToken,
        });
    };
    const showModalAfterLogout = (title) => {
        Modal.info({
            icon: null,
            content: "You have logged out. please log in again",
            cancelButtonProps:{style:{display: 'none'}},
            title: title || "Your session has expired",
        });
    };

    useEffect(() => {
        try{
            if(decodedToken){
                const today = moment().utc()
                const expired = moment(decodedToken.exp * 1000).utc()
                if(expired.isBefore(today)){
                    Modal.destroyAll()
                    history.push("/logout")
                } else {
                    const minutesUntilExpired = expired.diff(today, 'minutes')
                    clearTimeout(inactivityTimeout.current);
                    clearTimeout(modalTimeout.current);
                    inactivityTimeout.current = setTimeout(async () => {
                        // check inactivity 35 minutes before expired
                        startInactivityCheck()
                    }, (minutesUntilExpired - 35) * 60000)
                    modalTimeout.current = setTimeout(async () => {
                        if(isInactiveRef.current){
                            // remove listener when modal exists
                            setIsInactive(false)
                            // 5 minutes before expired, show modal if inactive
                            showModalAlert(decodedToken.exp)
                        }
                    }, (minutesUntilExpired - 5) * 60000)
                }
            }
        } catch (e) {
            console.error("~ Refresher ~ handleRefreshToken ~ error:", e)
        }
        return () => {
            clearTimeout(inactivityTimeout.current);
            clearTimeout(modalTimeout.current);
        }
    }, [decodedToken])
    
    const openNotification = (description) => {
		notification.open({
			message: "Notification",
			description,
		});
	};

    useEffect(() => {
        const target = message.target
        try{
            if(message.arguments && message.arguments.length > 0){
                const arg = message.arguments[0]
                const { content, moduleId, activity_id } = arg
                setTimeout(() => {
                    if(content){
                        openNotification(content);
                    }
                    if(moduleId){
                        queryClient.invalidateQueries({
                            predicate: query => {
                                return (query.queryKey[0] || '').match(/getModuleTasksDynamic|getDrafts|getModuleItems|getTasksByUser|getTaskScheduler/) && 
                                    query.queryKey[1]?.moduleId === moduleId
                            }
                        })
                        queryClient.invalidateQueries(['module-summary', moduleId])
                        queryClient.invalidateQueries(['module-history', moduleId])
                        queryClient.invalidateQueries(['module-record', moduleId])
                    }
                }, 7500); 
            } 
        } catch(e) {
            console.error("~ Refresher ~ useEffect ~ error:", e)
        }
    }, [message])
    
    const calculateDuration = eventTime => moment.duration(Math.max(eventTime - (Math.floor(Date.now() / 1000)), 0), 'seconds');

    const Countdown = ({ eventTime, interval, timerRef }) => {
        const [duration, setDuration] = useState(calculateDuration(eventTime));
        const timerCallback = useCallback(() => {
            try{
                if(calculateDuration(eventTime).asSeconds() <= 0){
                    clearInterval(timerRef.current);
                    // logout if expired
                    Modal.destroyAll()
                    showModalAfterLogout()
                    history.push("/logout")
                }
                setDuration(calculateDuration(eventTime));
            } catch (e){
                console.error("~ Countdown ~ timerCallback ~ error:", e)
            }
        }, [eventTime])

        useEffect(() => {
            timerRef.current = setInterval(timerCallback, interval);

            return () => {
                clearInterval(timerRef.current);
            }
        }, [eventTime]);

        return (
            <div>
                You will be logged out in {duration.minutes()} Minutes {duration.seconds()} Seconds
            </div>
        )
    }

    return children
}

export default Refresher