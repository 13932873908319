import { parseString } from "xml2js";
import { JSONPath } from "jsonpath-plus";
import Handlebars from "handlebars";
import { flowToElements } from "@utils/form-editor/normalize-response";

const activities = [
  'SendHttpRequest',
  'IfElse',
  'SetVariable',
  'Join',
  'Fork',
  'TextParser'
]

export const isValidConnector = (connector) => {
  const { connector_type: connectorType, request_data: requestData, security, json_workflow } = connector
  return (!['SOAP'].includes(connectorType) || (Boolean(requestData) && Boolean(security)) || json_workflow)
}

export const onConvertToJson = async (string) => {
  return new Promise((resolve) => {
    parseString(string, { 
      explicitRoot: false,
      explicitArray: false,
    }, (_err, result) => {
      if (_err) {
        resolve(string);
      }
      resolve(JSON.stringify(result));
    });
  })
}

const findNestedObj = (entireObj, keyToFind, valToFind) => {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

Object.byString = function(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
          o = o[k];
      } else {
          return;
      }
  }
  return o;
}

const checkIfUndefined = (val) => {
  return val ? val : "{}"
}

export const findActivity = async ({
  activity,
  responses,
  elements,
  connections,
  request,
}) => {
  const { activityId } = activity
  const connect = connections.find((con) => con.sourceActivityId === activityId && con.outcome === "Done");
  const element = elements.find((el) => el.id === connect.targetActivityId);
  const { raw } = element.data;
  if (activities.includes(element.type)) return { element }
  if (raw && raw.url) {
      const requestData = {
          url: checkIfUndefined(raw.url?.expression),
          method: raw.method,
          headers: checkIfUndefined(raw.requestHeaders?.expression),
          template: checkIfUndefined(raw.content?.expression),
          input: checkIfUndefined(raw.input?.expression),
          path: raw.query?.expression,
          multipleResult: Boolean(raw.multipleResult)
      }
      const res = await callHttpReq({ requestData, params: responses, request, connectorType: "SOAP" })
      responses[element.name] = res
      return {
          params: { [element.name]: res },
          element
      }
  }
  return {
      element: null
  }
}

export const getActivityResponses = async ({ connector, request }) => {
  try {
    if (!isValidConnector(connector)) return
    const { json_workflow = "{}" } = connector;
    const { activities, connections } = JSON.parse(json_workflow);
    const elements = flowToElements(JSON.parse(json_workflow));
    let responses = {};
    let lastActivity
    let activity = activities.find((act) => act.type === "StartProcess");
    if (!activity) return
    while (activity) {
        const res = await findActivity({activity, responses, elements, connections, request})
        if (res.element) {
            activity = res.element
            lastActivity = res.element
        } else {
            activity = null
        }
    }
    return {
      responses,
      lastActivity
    }
  } catch (err) { console.error(err, 'getActivityResponses') }
}

export const callHttpReq = async ({ requestData, params, connectorType, request }) => {
  if (!requestData || !requestData.url) return
  try {
    const {
      url,
      method,
      headers,
      template,
      input,
      path,
      multipleResult
    } = requestData;
    const bodyTemplate = Handlebars.compile(template);
    let vars = {};
    try {
      if (input.length > 0) {
        vars = JSON.parse(input);
      }
    } catch (_) {}
    vars = {
      ...vars,
      ...params
    }
    let headersData = typeof headers === "object" ? headers : JSON.parse(headers);
    const headerKeys = Object.keys(headersData) ?? {};
    const headersArr = headerKeys.map((Key) => ({
      Key,
      Value: headersData[Key]
    })) || [];
    const payload = {
      data: {
        url,
        httpMethod: method,
        contentType: "xml",
        headers: headersArr,
        bodyContent: bodyTemplate(vars),
        tokenizer: "",
        selectMultiple: connectorType !== 'SOAP' || multipleResult
      }
    }
    const response = await request(
      'remoteLookup',
      payload,
    );
    const { success, data } = response;
    if (success && data) {
      let jsonResponse = await onConvertToJson(data);
      let responseVal = findNestedObj(JSON.parse(jsonResponse), 'response')
      if (responseVal.startsWith("<?xml")) {
        jsonResponse = await onConvertToJson(responseVal);
        responseVal = path ? JSONPath({path, json: JSON.parse(jsonResponse) }) : findNestedObj(JSON.parse(jsonResponse), 'response')
      }
      return responseVal;
    }
    return false;
  } catch (err) {
    console.error({ err });
    throw new Error("Failed authenticating");
  }
}

export const callSecurity = async (security, connectorType, request) => {
  const {
    url,
    method,
    headers,
    template,
    username = "SSC",
    password = "K1$$flow",
    path: tokenizer,
    multipleResult
  } = security;
  if (!security || !url) return
  try {
    const bodyTemplate = Handlebars.compile(template);
    const requestVariables = {
      username,
      password,
    };
    let headersData = typeof headers === "object" ? headers : JSON.parse(headers);
    const headerKeys = Object.keys(headersData) ?? {};
    const headersArr = headerKeys.map((Key) => ({
      Key,
      Value: headersData[Key]
    })) || [];
    const payload = {
      data: {
        url,
        httpMethod: method,
        contentType: "xml",
        headers: headersArr,
        bodyContent: bodyTemplate(requestVariables),
        tokenizer,
        selectMultiple: connectorType !== 'SOAP' || multipleResult
      }
    }
    const response = await request(
        'remoteLookup',
        payload,
    );
    const { data, success } = response;
    if (success && data) {
      return data;
    }
    return false;
  } catch (err) {
    console.error({ err });
    throw new Error("Failed authenticating");
  }
}

export const callRequestData = async (request_data, connectorType, request, auth, params) => {
  const {
    url,
    method,
    headers,
    template,
    input,
    path: tokenizer,
    multipleResult
  } = request_data;
  if (!request_data || !url) return
  try {
    const bodyTemplate = Handlebars.compile(template);
    let vars = {};
    try {
      if (input.length > 0) {
        vars = JSON.parse(input);
      }
      vars = {
        ...vars,
        ...params
      }
    } catch (_) {}
    const requestVariables = {
      auth,
      ...vars
    };
    let headersData = typeof headers === "object" ? headers : JSON.parse(headers);
    const headerKeys = Object.keys(headersData) ?? {};
    const headersArr = headerKeys.map((Key) => ({
      Key,
      Value: headersData[Key]
    })) || [];
    const payload = {
      data: {
        url,
        httpMethod: method,
        contentType: "xml",
        headers: headersArr,
        bodyContent: bodyTemplate(requestVariables),
        tokenizer,
        selectMultiple: connectorType !== 'SOAP' || multipleResult
      }
    }
    const response = await request(
      'remoteLookup',
      payload,
    );
    const { success, data } = response;
    if (success && data) {
      const jsonResponse = await onConvertToJson(data);
      return JSON.parse(jsonResponse);
    }
    return false;
  } catch (err) {
    console.error({ err });
    return false;
  }
}