const SIDEBAR_ICONS = {
  icons: [
    {
        "prefix": "far",
        "name": "fa-address-book"
    },
    {
        "prefix": "far",
        "name": "fa-address-card"
    },
    {
        "prefix": "far",
        "name": "fa-archive"
    },
    {
        "prefix": "far",
        "name": "fa-badge"
    },
    {
        "prefix": "far",
        "name": "fa-badge-check"
    },
    {
        "prefix": "far",
        "name": "fa-badge-dollar"
    },
    {
        "prefix": "far",
        "name": "fa-badge-percent"
    },
    {
        "prefix": "far",
        "name": "fa-balance-scale"
    },
    {
        "prefix": "far",
        "name": "fa-balance-scale-left"
    },
    {
        "prefix": "far",
        "name": "fa-balance-scale-right"
    },
    {
        "prefix": "far",
        "name": "fa-birthday-cake"
    },
    {
        "prefix": "far",
        "name": "fa-book"
    },
    {
        "prefix": "far",
        "name": "fa-briefcase"
    },
    {
        "prefix": "far",
        "name": "fa-building"
    },
    {
        "prefix": "far",
        "name": "fa-bullhorn"
    },
    {
        "prefix": "far",
        "name": "fa-bullseye"
    },
    {
        "prefix": "far",
        "name": "fa-business-time"
    },
    {
        "prefix": "far",
        "name": "fa-cabinet-filing"
    },
    {
        "prefix": "far",
        "name": "fa-calculator"
    },
    {
        "prefix": "far",
        "name": "fa-calendar"
    },
    {
        "prefix": "far",
        "name": "fa-calendar-alt"
    },
    {
        "prefix": "far",
        "name": "fa-certificate"
    },
    {
        "prefix": "far",
        "name": "fa-chart-area"
    },
    {
        "prefix": "far",
        "name": "fa-chart-bar"
    },
    {
        "prefix": "far",
        "name": "fa-chart-line"
    },
    {
        "prefix": "far",
        "name": "fa-chart-line-down"
    },
    {
        "prefix": "far",
        "name": "fa-chart-network"
    },
    {
        "prefix": "far",
        "name": "fa-chart-pie"
    },
    {
        "prefix": "far",
        "name": "fa-chart-pie-alt"
    },
    {
        "prefix": "far",
        "name": "fa-chart-scatter"
    },
    {
        "prefix": "far",
        "name": "fa-city"
    },
    {
        "prefix": "far",
        "name": "fa-clipboard"
    },
    {
        "prefix": "far",
        "name": "fa-coffee"
    },
    {
        "prefix": "far",
        "name": "fa-coffee-pot"
    },
    {
        "prefix": "far",
        "name": "fa-columns"
    },
    {
        "prefix": "far",
        "name": "fa-compass"
    },
    {
        "prefix": "far",
        "name": "fa-computer-classic"
    },
    {
        "prefix": "far",
        "name": "fa-copy"
    },
    {
        "prefix": "far",
        "name": "fa-copyright"
    },
    {
        "prefix": "far",
        "name": "fa-cut"
    },
    {
        "prefix": "far",
        "name": "fa-edit"
    },
    {
        "prefix": "far",
        "name": "fa-envelope"
    },
    {
        "prefix": "far",
        "name": "fa-envelope-open"
    },
    {
        "prefix": "far",
        "name": "fa-envelope-square"
    },
    {
        "prefix": "far",
        "name": "fa-eraser"
    },
    {
        "prefix": "far",
        "name": "fa-fax"
    },
    {
        "prefix": "far",
        "name": "fa-file"
    },
    {
        "prefix": "far",
        "name": "fa-file-alt"
    },
    {
        "prefix": "far",
        "name": "fa-file-chart-line"
    },
    {
        "prefix": "far",
        "name": "fa-file-chart-pie"
    },
    {
        "prefix": "far",
        "name": "fa-file-spreadsheet"
    },
    {
        "prefix": "far",
        "name": "fa-file-user"
    },
    {
        "prefix": "far",
        "name": "fa-folder"
    },
    {
        "prefix": "far",
        "name": "fa-folder-download"
    },
    {
        "prefix": "far",
        "name": "fa-folder-minus"
    },
    {
        "prefix": "far",
        "name": "fa-folder-open"
    },
    {
        "prefix": "far",
        "name": "fa-folder-plus"
    },
    {
        "prefix": "far",
        "name": "fa-folder-times"
    },
    {
        "prefix": "far",
        "name": "fa-folder-tree"
    },
    {
        "prefix": "far",
        "name": "fa-folder-upload"
    },
    {
        "prefix": "far",
        "name": "fa-folders"
    },
    {
        "prefix": "far",
        "name": "fa-glasses"
    },
    {
        "prefix": "far",
        "name": "fa-globe"
    },
    {
        "prefix": "far",
        "name": "fa-highlighter"
    },
    {
        "prefix": "far",
        "name": "fa-industry"
    },
    {
        "prefix": "far",
        "name": "fa-industry-alt"
    },
    {
        "prefix": "far",
        "name": "fa-keynote"
    },
    {
        "prefix": "far",
        "name": "fa-lamp-desk"
    },
    {
        "prefix": "far",
        "name": "fa-landmark"
    },
    {
        "prefix": "far",
        "name": "fa-laptop-house"
    },
    {
        "prefix": "far",
        "name": "fa-marker"
    },
    {
        "prefix": "far",
        "name": "fa-mind-share"
    },
    {
        "prefix": "far",
        "name": "fa-money-check-edit"
    },
    {
        "prefix": "far",
        "name": "fa-money-check-edit-alt"
    },
    {
        "prefix": "far",
        "name": "fa-paperclip"
    },
    {
        "prefix": "far",
        "name": "fa-paste"
    },
    {
        "prefix": "far",
        "name": "fa-pen"
    },
    {
        "prefix": "far",
        "name": "fa-pen-alt"
    },
    {
        "prefix": "far",
        "name": "fa-pen-fancy"
    },
    {
        "prefix": "far",
        "name": "fa-pen-nib"
    },
    {
        "prefix": "far",
        "name": "fa-pen-square"
    },
    {
        "prefix": "far",
        "name": "fa-pencil"
    },
    {
        "prefix": "far",
        "name": "fa-pencil-alt"
    },
    {
        "prefix": "far",
        "name": "fa-percent"
    },
    {
        "prefix": "far",
        "name": "fa-phone"
    },
    {
        "prefix": "far",
        "name": "fa-phone-alt"
    },
    {
        "prefix": "far",
        "name": "fa-phone-office"
    },
    {
        "prefix": "far",
        "name": "fa-phone-slash"
    },
    {
        "prefix": "far",
        "name": "fa-phone-square"
    },
    {
        "prefix": "far",
        "name": "fa-phone-square-alt"
    },
    {
        "prefix": "far",
        "name": "fa-phone-volume"
    },
    {
        "prefix": "far",
        "name": "fa-podium"
    },
    {
        "prefix": "far",
        "name": "fa-presentation"
    },
    {
        "prefix": "far",
        "name": "fa-print"
    },
    {
        "prefix": "far",
        "name": "fa-print-slash"
    },
    {
        "prefix": "far",
        "name": "fa-project-diagram"
    },
    {
        "prefix": "far",
        "name": "fa-projector"
    },
    {
        "prefix": "far",
        "name": "fa-registered"
    },
    {
        "prefix": "far",
        "name": "fa-router"
    },
    {
        "prefix": "far",
        "name": "fa-save"
    },
    {
        "prefix": "far",
        "name": "fa-scanner-image"
    },
    {
        "prefix": "far",
        "name": "fa-shredder"
    },
    {
        "prefix": "far",
        "name": "fa-sitemap"
    },
    {
        "prefix": "far",
        "name": "fa-socks"
    },
    {
        "prefix": "far",
        "name": "fa-sticky-note"
    },
    {
        "prefix": "far",
        "name": "fa-stream"
    },
    {
        "prefix": "far",
        "name": "fa-table"
    },
    {
        "prefix": "far",
        "name": "fa-tag"
    },
    {
        "prefix": "far",
        "name": "fa-tags"
    },
    {
        "prefix": "far",
        "name": "fa-tasks"
    },
    {
        "prefix": "far",
        "name": "fa-tasks-alt"
    },
    {
        "prefix": "far",
        "name": "fa-thumbtack"
    },
    {
        "prefix": "far",
        "name": "fa-trademark"
    },
    {
        "prefix": "far",
        "name": "fa-user-chart"
    },
    {
        "prefix": "far",
        "name": "fa-wallet"
    },
    {
        "prefix": "far",
        "name": "fa-abacus"
    },
    {
        "prefix": "far",
        "name": "fa-acorn"
    },
    {
        "prefix": "far",
        "name": "fa-ad"
    },
    {
        "prefix": "far",
        "name": "fa-address-book"
    },
    {
        "prefix": "far",
        "name": "fa-address-card"
    },
    {
        "prefix": "far",
        "name": "fa-adjust"
    },
    {
        "prefix": "far",
        "name": "fa-air-conditioner"
    },
    {
        "prefix": "far",
        "name": "fa-air-freshener"
    },
    {
        "prefix": "far",
        "name": "fa-alarm-clock"
    },
    {
        "prefix": "far",
        "name": "fa-alarm-exclamation"
    },
    {
        "prefix": "far",
        "name": "fa-alarm-plus"
    },
    {
        "prefix": "far",
        "name": "fa-alarm-snooze"
    },
    {
        "prefix": "far",
        "name": "fa-album"
    },
    {
        "prefix": "far",
        "name": "fa-album-collection"
    },
    {
        "prefix": "far",
        "name": "fa-alicorn"
    },
    {
        "prefix": "far",
        "name": "fa-alien"
    },
    {
        "prefix": "far",
        "name": "fa-alien-monster"
    },
    {
        "prefix": "far",
        "name": "fa-align-center"
    },
    {
        "prefix": "far",
        "name": "fa-align-justify"
    },
    {
        "prefix": "far",
        "name": "fa-align-left"
    },
    {
        "prefix": "far",
        "name": "fa-align-right"
    },
    {
        "prefix": "far",
        "name": "fa-align-slash"
    },
    {
        "prefix": "far",
        "name": "fa-allergies"
    },
    {
        "prefix": "far",
        "name": "fa-ambulance"
    },
    {
        "prefix": "far",
        "name": "fa-american-sign-language-interpreting"
    },
    {
        "prefix": "far",
        "name": "fa-amp-guitar"
    },
    {
        "prefix": "far",
        "name": "fa-analytics"
    },
    {
        "prefix": "far",
        "name": "fa-anchor"
    },
    {
        "prefix": "far",
        "name": "fa-angel"
    },
    {
        "prefix": "far",
        "name": "fa-angle-double-down"
    },
    {
        "prefix": "far",
        "name": "fa-angle-double-left"
    },
    {
        "prefix": "far",
        "name": "fa-angle-double-right"
    },
    {
        "prefix": "far",
        "name": "fa-angle-double-up"
    },
    {
        "prefix": "far",
        "name": "fa-angle-down"
    },
    {
        "prefix": "far",
        "name": "fa-angle-left"
    },
    {
        "prefix": "far",
        "name": "fa-angle-right"
    },
    {
        "prefix": "far",
        "name": "fa-angle-up"
    },
    {
        "prefix": "far",
        "name": "fa-angry"
    },
    {
        "prefix": "far",
        "name": "fa-ankh"
    },
    {
        "prefix": "far",
        "name": "fa-apple-alt"
    },
    {
        "prefix": "far",
        "name": "fa-apple-crate"
    },
    {
        "prefix": "far",
        "name": "fa-archive"
    },
    {
        "prefix": "far",
        "name": "fa-archway"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-circle-down"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-circle-left"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-circle-right"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-circle-up"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-down"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-from-bottom"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-from-left"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-from-right"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-from-top"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-left"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-right"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-square-down"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-square-left"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-square-right"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-square-up"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-to-bottom"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-to-left"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-to-right"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-to-top"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-alt-up"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-circle-down"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-circle-left"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-circle-right"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-circle-up"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-down"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-from-bottom"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-from-left"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-from-right"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-from-top"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-left"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-right"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-square-down"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-square-left"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-square-right"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-square-up"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-to-bottom"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-to-left"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-to-right"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-to-top"
    },
    {
        "prefix": "far",
        "name": "fa-arrow-up"
    },
    {
        "prefix": "far",
        "name": "fa-arrows"
    },
    {
        "prefix": "far",
        "name": "fa-arrows-alt"
    },
    {
        "prefix": "far",
        "name": "fa-arrows-alt-h"
    },
    {
        "prefix": "far",
        "name": "fa-arrows-alt-v"
    },
    {
        "prefix": "far",
        "name": "fa-arrows-h"
    },
    {
        "prefix": "far",
        "name": "fa-arrows-v"
    },
    {
        "prefix": "far",
        "name": "fa-assistive-listening-systems"
    },
    {
        "prefix": "far",
        "name": "fa-asterisk"
    },
    {
        "prefix": "far",
        "name": "fa-at"
    },
    {
        "prefix": "far",
        "name": "fa-atlas"
    },
    {
        "prefix": "far",
        "name": "fa-atom"
    },
    {
        "prefix": "far",
        "name": "fa-atom-alt"
    },
    {
        "prefix": "far",
        "name": "fa-audio-description"
    },
    {
        "prefix": "far",
        "name": "fa-award"
    },
    {
        "prefix": "far",
        "name": "fa-axe"
    },
    {
        "prefix": "far",
        "name": "fa-axe-battle"
    },
    {
        "prefix": "far",
        "name": "fa-baby"
    },
    {
        "prefix": "far",
        "name": "fa-baby-carriage"
    },
    {
        "prefix": "far",
        "name": "fa-backpack"
    },
    {
        "prefix": "far",
        "name": "fa-backspace"
    },
    {
        "prefix": "far",
        "name": "fa-backward"
    },
    {
        "prefix": "far",
        "name": "fa-bacon"
    },
    {
        "prefix": "far",
        "name": "fa-bacteria"
    },
    {
        "prefix": "far",
        "name": "fa-bacterium"
    },
    {
        "prefix": "far",
        "name": "fa-badge"
    },
    {
        "prefix": "far",
        "name": "fa-badge-check"
    },
    {
        "prefix": "far",
        "name": "fa-badge-dollar"
    },
    {
        "prefix": "far",
        "name": "fa-badge-percent"
    },
    {
        "prefix": "far",
        "name": "fa-badge-sheriff"
    },
    {
        "prefix": "far",
        "name": "fa-badger-honey"
    },
    {
        "prefix": "far",
        "name": "fa-bags-shopping"
    },
    {
        "prefix": "far",
        "name": "fa-bahai"
    },
    {
        "prefix": "far",
        "name": "fa-balance-scale"
    },
    {
        "prefix": "far",
        "name": "fa-balance-scale-left"
    },
    {
        "prefix": "far",
        "name": "fa-balance-scale-right"
    },
    {
        "prefix": "far",
        "name": "fa-ball-pile"
    },
    {
        "prefix": "far",
        "name": "fa-ballot"
    },
    {
        "prefix": "far",
        "name": "fa-ballot-check"
    },
    {
        "prefix": "far",
        "name": "fa-ban"
    },
    {
        "prefix": "far",
        "name": "fa-band-aid"
    },
    {
        "prefix": "far",
        "name": "fa-banjo"
    },
    {
        "prefix": "far",
        "name": "fa-barcode"
    },
    {
        "prefix": "far",
        "name": "fa-barcode-alt"
    },
    {
        "prefix": "far",
        "name": "fa-barcode-read"
    },
    {
        "prefix": "far",
        "name": "fa-barcode-scan"
    },
    {
        "prefix": "far",
        "name": "fa-bars"
    },
    {
        "prefix": "far",
        "name": "fa-baseball"
    },
    {
        "prefix": "far",
        "name": "fa-baseball-ball"
    },
    {
        "prefix": "far",
        "name": "fa-basketball-ball"
    },
    {
        "prefix": "far",
        "name": "fa-basketball-hoop"
    },
    {
        "prefix": "far",
        "name": "fa-bat"
    },
    {
        "prefix": "far",
        "name": "fa-bath"
    },
    {
        "prefix": "far",
        "name": "fa-battery-bolt"
    },
    {
        "prefix": "far",
        "name": "fa-battery-empty"
    },
    {
        "prefix": "far",
        "name": "fa-battery-full"
    },
    {
        "prefix": "far",
        "name": "fa-battery-half"
    },
    {
        "prefix": "far",
        "name": "fa-battery-quarter"
    },
    {
        "prefix": "far",
        "name": "fa-battery-slash"
    },
    {
        "prefix": "far",
        "name": "fa-battery-three-quarters"
    },
    {
        "prefix": "far",
        "name": "fa-bed"
    },
    {
        "prefix": "far",
        "name": "fa-bed-alt"
    },
    {
        "prefix": "far",
        "name": "fa-bed-bunk"
    },
    {
        "prefix": "far",
        "name": "fa-bed-empty"
    },
    {
        "prefix": "far",
        "name": "fa-beer"
    },
    {
        "prefix": "far",
        "name": "fa-bell"
    },
    {
        "prefix": "far",
        "name": "fa-bell-exclamation"
    },
    {
        "prefix": "far",
        "name": "fa-bell-on"
    },
    {
        "prefix": "far",
        "name": "fa-bell-plus"
    },
    {
        "prefix": "far",
        "name": "fa-bell-school"
    },
    {
        "prefix": "far",
        "name": "fa-bell-school-slash"
    },
    {
        "prefix": "far",
        "name": "fa-bell-slash"
    },
    {
        "prefix": "far",
        "name": "fa-bells"
    },
    {
        "prefix": "far",
        "name": "fa-betamax"
    },
    {
        "prefix": "far",
        "name": "fa-bezier-curve"
    },
    {
        "prefix": "far",
        "name": "fa-bible"
    },
    {
        "prefix": "far",
        "name": "fa-bicycle"
    },
    {
        "prefix": "far",
        "name": "fa-biking"
    },
    {
        "prefix": "far",
        "name": "fa-biking-mountain"
    },
    {
        "prefix": "far",
        "name": "fa-binoculars"
    },
    {
        "prefix": "far",
        "name": "fa-biohazard"
    },
    {
        "prefix": "far",
        "name": "fa-birthday-cake"
    },
    {
        "prefix": "far",
        "name": "fa-blanket"
    },
    {
        "prefix": "far",
        "name": "fa-blender"
    },
    {
        "prefix": "far",
        "name": "fa-blender-phone"
    },
    {
        "prefix": "far",
        "name": "fa-blind"
    },
    {
        "prefix": "far",
        "name": "fa-blinds"
    },
    {
        "prefix": "far",
        "name": "fa-blinds-open"
    },
    {
        "prefix": "far",
        "name": "fa-blinds-raised"
    },
    {
        "prefix": "far",
        "name": "fa-blog"
    },
    {
        "prefix": "far",
        "name": "fa-bold"
    },
    {
        "prefix": "far",
        "name": "fa-bolt"
    },
    {
        "prefix": "far",
        "name": "fa-bomb"
    },
    {
        "prefix": "far",
        "name": "fa-bone"
    },
    {
        "prefix": "far",
        "name": "fa-bone-break"
    },
    {
        "prefix": "far",
        "name": "fa-bong"
    },
    {
        "prefix": "far",
        "name": "fa-book"
    },
    {
        "prefix": "far",
        "name": "fa-book-alt"
    },
    {
        "prefix": "far",
        "name": "fa-bug"
    },
    {
        "prefix": "far",
        "name": "fa-baby-carriage"
    },
    {
        "prefix": "far",
        "name": "fa-carrot"
    },
    {
        "prefix": "far",
        "name": "fa-spider"
    },
    {
        "prefix": "far",
        "name": "fa-spider-black-widow"
    },
    {
        "prefix": "far",
        "name": "fa-trumpet"
    },
    {
        "prefix": "far",
        "name": "fa-virus"
    },
    {
        "prefix": "far",
        "name": "fa-virus-slash"
    },
    {
        "prefix": "far",
        "name": "fa-viruses"
    },
    {
        "prefix": "far",
        "name": "fa-debug"
    },
    {
        "prefix": "far",
        "name": "fa-seedling"
    },
    {
        "prefix": "far",
        "name": "fa-signal"
    },
    {
        "prefix": "far",
        "name": "fa-shield-check"
    }
  ]
}

export default SIDEBAR_ICONS;