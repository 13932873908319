import React from 'react';

import Flex from '../components/Flex';
import Head from '../components/Head';
import Aroute from '../components/Aroute';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { APP_NAME, APP_YEAR } from '../../data/appData';

export default function AuthLayout({
    title = "",
    ktLoginStyle,
    loginAsideStyle,
    asideContent,
    children,
}) {

    return (
        <Flex dir="column" className="flex-root">
            {title && <Head title={title}/>}

            <Flex id="kt_login"
                  dir="column"
                  className="login login-1 login-signin-on flex-lg-row flex-row-fluid bg-light bgi-no-repeat bgi-size-cover"
                  style={ktLoginStyle}
            >
                <Flex
                    className="col-4 flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                    style={loginAsideStyle}
                >
                    <Flex dir="column" justify="between" className="flex-row-fluid">
                        <Aroute to="/" className="flex-column-auto align-self-start mt-5">
                            <img
                                height="50"
                                loading="lazy"
                                alt={APP_NAME}
                                src={toAbsoluteUrl("/media/logos/UniFi-White.svg")} // finansys_flow_white_2.svg
                            />
                        </Aroute>

                        <Flex dir="column" justify="start" className="flex-column-fluid mb-30 pt-20 text-white">
                            {asideContent}
                        </Flex>

                        <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                            <div className="font-weight-bold text-white">{/* opacity-70  */}
                                &copy; {APP_YEAR} FinanSys Limited
                            </div>
                        </div>
                    </Flex>
                </Flex>

                <Flex dir="column" className="flex-row-fluid position-relative p-7 overflow-hidden bg-white">
                    <Flex className="flex-column-fluid flex-center mt-30 mt-lg-0">
                        {children}
                    </Flex>
                </Flex>

            </Flex>
        </Flex>
    );
}

