import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { forgotPassword } from "../_redux/authCrud";
import { Spin } from 'antd';
import _message from '@utils/message';

import { LoadingOutlined } from '@ant-design/icons';
const loadingIcon = <LoadingOutlined style={{ fontSize: 17, color: "#fff", marginRight: 5 }} spin />;

const initialValues = {
  username: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    username: Yup.string()
      //.email("Wrong username format")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Username is required"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      forgotPassword(values.username)
        .then(res => {
          setIsRequested(true)
          if (res?.data?.success) {
            _message.success(res?.data?.message);
          } else {
            _message.error(res?.data?.message);
          }
        }).catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.username }
            )
          );
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Forgotten Password ?</h3>
            <div className="text-muted font-weight-bold">
              Enter your username to reset your password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container mb-3">
              <input
                type="username"
                className={`form-control form-control-solid h-auto py-4 px-6 ${getInputClasses(
                  "username"
                )}`}
                name="username"
                placeholder="Username"
                autoComplete="off"
                {...formik.getFieldProps("username")}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="fv-plugins-message-container text-left">
                  <div className="fv-help-block">{formik.errors.username}</div>
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col-6">

                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 w-100 my-3"
                  disabled={formik.isSubmitting}
                  style={{ height: 38 }}
                >
                  {formik.isSubmitting ? <>
                    <Spin indicator={loadingIcon} /> Loading
                  </> : "Submit"}
                </button>
              </div>
              <div className="col-6">

                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 w-100 my-3"
                    disabled={formik.isSubmitting}
                    style={{ height: 38 }}
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </div>
            {/* <div className="form-group d-flex flex-wrap flex-center">
            </div> */}
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
