const taskSchedulerEndpoints = {
    saveTaskScheduler({ taskSchedulerId, data }) {
        return {
            url: '/api/TaskScheduler/save',
            method: 'POST',
            params: {
                taskSchedulerId,
            },
            data,
        }
    },
    getTaskScheduler({ moduleId, data }) {
        return {
            url: '/api/TaskScheduler/datatables',
            method: 'POST',
            params: {
                moduleId,
            },
            data,
        }
    },
    loadRecordTaskScheduler({ recordId }) {
        return {
            url: `/api/TaskScheduler/${recordId}/detail`,
            method: 'GET',
            params: {
                recordId,
            },
        }
    },
    deleteTaskSchedulerEntries({ moduleId, recordIds }) {
        return {
            url: '/api/TaskScheduler/delete',
            method: 'POST',
            params: {
                moduleId,
            },
            data: recordIds,
        }
    },
    loadSchedulerLogs({ schedulerId }) {
        return {
            url: `/api/TaskScheduler/activities`,
            method: 'GET',
            params: {
                schedulerId
            },
        }
    },
    taskScheduleAction({ schedule_id, status }) {
        return {
            url: `/api/TaskScheduler/action`,
            method: 'POST',
            data: {
                schedule_id: schedule_id,
                status: status
            },
        }
    },
}

export default taskSchedulerEndpoints