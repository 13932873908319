import { message as antdMessage } from "antd";

const message = {}
message.error = (msg, duration = 0) => {
    if (typeof msg === 'object') {
        return antdMessage.error({
            content: msg, 
            duration: duration, 
            onClick: () => {
                antdMessage.destroy()
            }
        })
    }
    return antdMessage.error({
        content: msg, 
        duration: duration, 
        onClick: () => {
            antdMessage.destroy()
        }
    })
}
message.success = antdMessage.success
message.warning = antdMessage.warning

export default message