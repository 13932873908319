// Number methods:
export function num2Hex(n){
	return n.toString(16);
}

export function calculatePercentageValue(value, format) {
	const isPercentage = format === 'Percentage'
	return value && isPercentage
		? Number(value) / 100
		: value
}

export function restorePercentageValue(value, format, decimalPlaces = 0) {
	const isPercentage = format === 'Percentage'
	return value && isPercentage
		? Number((value * 100).toFixed(decimalPlaces))
		: value
}

export function getNumberSeparators(value){
	if (/^\d{1,3}(,\d{3})*(\.\d*)?$/.test(value) || /^\d*(\.\d*)?$/.test(value)) {
        return { thousand: ',', decimal: '.' };
    }    
	return { thousand: '.', decimal: ',' };
}

export default {
	num2Hex,
	calculatePercentageValue,
	restorePercentageValue,
	getNumberSeparators
};