import api, {client} from "@api";

export const setLoading = (key, status) => ({
    type: 'SET_LOADING',
    payload: {
        key,
        status,
    }
})

export const initRequest = (dispatch = () => {}, fieldId = '', normalizer) => async (path, payload = {}, onSuccess, onComplete) => {
    let response = null;
    await dispatch(setLoading(`${path}${fieldId}`, true))
    try {
        const { method: requestMethod = '', data: requestData, params, contentType } = payload || {}
        let requestCall = () => api[path](payload)
        if (!api[path]) {
            const method = requestMethod.toLowerCase()
            if (contentType) {
                client.interceptors.request.use(config => {
                  config.headers[method]['Content-Type'] = contentType;
                  config.headers[method]['soapAction'] = 'http://systemsunion.com/connect/webservices/Authenticate';
                  return config;
                });
            }
            requestCall = () => client.request({
                url: path,
                method: method,
                ...['post', 'put'].includes(method) && requestData
                    ? { data: requestData }
                    : {},
                ...method === 'get' && params
                    ? { params }
                    : {},
            })
        }
        const apiResponse = await requestCall();
        const { data } = apiResponse || {}
        response = data || {};
        onSuccess && onSuccess(data || {});
    } catch (e) {
        response = { success: false, message: e.message }
        console.error(e)
    } finally {
        dispatch(setLoading(`${path}${fieldId}`, false));
        onComplete && onComplete(response)
    }
    if (typeof normalizer === 'function') {
        const data = await normalizer(response)
        response = { data }
    }
    return response
}

export default {
    setLoading,
    initRequest,
}