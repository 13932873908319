const textractEndpoints = {    
    getMLServices() {
        return {
            url: `/api/ocr/getmlservices`,
            method: 'GET',
        }
    },
    getMLService(id) {
        return {
            url: `/api/ocr/getmlservice`,
            method: 'GET',
            params: {id}
        }
    },
    getAIServices({mlServiceId}) {
        return {
            url: `/api/ocr/getaiservices`,
            method: 'GET',
            params:{ mlServiceId }
        }
    },
    getAIService(id) {
        return {
            url: `/api/ocr/getaiservice`,
            method: 'GET',
            params: {id}
        }
    },
    uploadFile({ data = {} }) {
        return {
            url: '/api/ocr/upload',
            method: 'POST',
            data,
        }
    },
    getOcrResponseById(id) {
        return {
            url: `/api/ocr/getresponsebyid`,
            method: 'GET',
            params: {id}
        }
    },
}

export default textractEndpoints