const initialState = {
    loading: {},
    options: {},
    govHeaders: null,
}

const optionsReducer = (state = initialState, action) => {
    const { key, status, optionKey, optionList } = action.payload || {}

    switch (action.type) {
        case 'SET_LOADING':
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [key]: status,
                }
            }
        case 'SET_OPTIONS':
            return {
                ...state,
                options: {
                    ...state.options,
                    [optionKey]: optionList,
                }
            }
        case 'SET_GOV_HEADERS':
            return {
                ...state,
                govHeaders: action.payload,
            }
        default:
            return { ...state }
    }
}

export default optionsReducer