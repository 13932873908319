
const initialState = {
    preference: {}
}

const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ORGANIZATION_PREFERENCE':
            const preference = action.payload ?? preference;     
            return {
                ...state,
                preference: preference
            }
        default:
            return { ...state }
    }
}

export default organizationReducer