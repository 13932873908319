import cls from 'classnames';
import { isJsonString } from '@utils/json';

/** Check support native lazy loading image, iframe */
// const SUPPORT_LOADING = 'loading' in HTMLImageElement.prototype;

// https://medium.com/javascript-in-plain-english/you-must-understand-these-14-javasript-functions-1f4fa1c620e2
/** FROM: Vue.js */
function cached(fn){
	let cache = Object.create(null);
	return (function cachedFn(s){
		let hit = cache[s];
		return hit || (cache[s] = fn(s))
	})
}

/** Number format */
export const numberFormat = (lang = "en-GB", options = {}, value = 1) => new Intl.NumberFormat(lang, options).format(value);

/** === Type checking === */
function isStr(v){
	return typeof v === 'string' || v instanceof String;
}
function isObj(v){
	return v && typeof v === 'object' && v.constructor === Object;
}
function isFunc(v){
	return v && typeof v === 'function';
}
function isBool(v){
	return typeof v === "boolean";
}
function isNum(v){
	return typeof v === "number" && !isNaN(v);
}
/** === END Type checking === */

/** classnames return undefined if length < 1 for prevent react render class="" */
function Cx(){
	return cls.apply(null, arguments) || undefined;
}

/** == dom function === */
function domQ(q, dom = document){
  return dom.querySelector(q);
}
function domQall(q, dom = document){
	return dom.querySelectorAll(q);
}
/** @USAGE:
	add = setClass(element, "btn active");
	remove = setClass(element, "btn active", 'remove'); */
function setClass(el, c, fn = "add"){
  let cls = c.split(" ");
  el.classList[fn](...cls);
}
function hasClass(el, c){
  return el.classList.contains(c);
}
function toggleClass(el, c, cek){
  el.classList.toggle(c, cek);
}
function hasAttr(el, a) {
	if (el && a) return el.hasAttribute(a);
	return false;
}
function getAttr(el, a){
  return el.getAttribute(a);
}
/**
	@el 	: DOM element / node
	@attr : valid attribute name & value (Object)
*/
function setAttr(el, attr){
	if(el){
		if(isObj(attr)){
			for(let key in attr){
				el.setAttribute(key, attr[key]);
			}
		}
		else if(isStr(attr)) attr.split(" ").forEach(v => el.removeAttribute(v));
		else console.warn('setAttr() : params 2 required Object to add / string to remove, To remove several attributes, separate the attribute names with a space.');
	}
}
/** === END dom function === */

/** === Generate id === */
function Qid(){
 	function S4(){
  	return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
 	}
 	return 'fs-' + (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4());
}

/** No Action */
function preventQ(e){
  e.preventDefault();
  e.stopPropagation();
}
//function noop(){};
/** END No Action */

/** Bind multiple class component methods:
	* @param {this} context
	* @param {Array} functions
	constructor(){
		...
		bindFuncs.call(this,['onClick','onModal']);
	} */
//function bindFuncs(fns){
//	fns.forEach(f => (this[f] = this[f].bind(this)));
//}

/** FROM reactstrap utils */
function omit(obj, omitKeys){
  let res = {};
  Object.keys(obj).forEach(k => {
    if(omitKeys.indexOf(k) === -1) res[k] = obj[k];
  });
  return res;
}
/** END reactstrap utils */

function jsonParse(val, returnErr = {}){
  try{
    return isStr(val) ? JSON.parse(val) : returnErr;
  }catch(e){
    return returnErr;
  }
}

const makeEl = t => document.createElement(t);

/** String Function */
function toCapital(s) {
	return s[0].toUpperCase() + s.slice(1);
}

// Check mobile device:
function isMobile(){
  return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

// function isSupportLocaleDateString(date){
  // try {
    // date.toLocaleDateString('i');// new Date().toLocaleDateString('i');
  // }catch(e){
    // return e.name === 'RangeError';
  // }
  // return false;
// }

// function isValidLang(lang){
// 	if(!window.Intl) return;
// 	try {
// 		let supportLocOf = Intl.DateTimeFormat.supportedLocalesOf(lang, {localeMatcher:'lookup'});// lookup | best fit
// 		let isOk = supportLocOf && Intl.getCanonicalLocales(lang) ? supportLocOf.length : false;
// 		return isOk;
// 	}catch(e){
// 		console.warn(e.message);// expected output: RangeError: invalid language tag: lang
// 	}
// }

/** Check html tagName **/
/* function isTag(t){ // tagCheck
	// let c = document.createElement(t),
			// b = c.toString() !== "[object HTMLUnknownElement]"
  // return {valid:b, el:c};
	return document.createElement(t).toString() !== "[object HTMLUnknownElement]";
} */

//const newURL = (path = "/") => new URL(path, window.location.origin);

// FROM https://github.com/chakra-ui/chakra-ui/blob/master/packages/chakra-ui/src/Avatar/index.js
const getInitials = (name, no = "?") => {
	if(!name || !isStr(name) || name === " " || name.length < 1) return no;
	// Destruct 
  let [first, last] = name.split(" ");

  if(first && last){
    return first[0] + last[0];
  }
	return first[0];
};

const obj2formData = (obj) => {
	let fd = new FormData();
	for(let key in obj){
		fd.append(key, obj[key]);
	}
	return fd;
}

const formikValidClass = (formik, field) => {
	let touch = formik.touched[field];
	let err = formik.errors[field];
	if (touch && err) {
		return " is-invalid";
	}
	if (touch && !err) {
		return " is-valid";
	}
	return "";
};

const getParam = (key) => new URLSearchParams(window.location.search).get(key);

function getLocalISOString() {
	// Get local time as ISO string with offset at the end
	let now = new Date();
	let tzo = -now.getTimezoneOffset();
	let dif = tzo >= 0 ? '+' : '-';
	let pad = function(n, width) {
			width = width || 2;
			n = Math.abs(Math.floor(n)) + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
	};
	return now.getFullYear() 
			+ '-' + pad(now.getMonth()+1)
			+ '-' + pad(now.getDate())
			+ 'T' + pad(now.getHours())
			+ ':' + pad(now.getMinutes()) 
			+ ':' + pad(now.getSeconds())
			+ '.' + pad(now.getMilliseconds(),3)
			+ dif + pad(tzo / 60) 
			+ ':' + pad(tzo % 60);
}

// const unescapeHTML = str => str.replace(
// 	/&amp;|&lt;|&gt;|&#39;|&quot;/g,
// 	tag => ({
// 			'&amp;': '&',
// 			'&lt;': '<',
// 			'&gt;': '>',
// 			'&#39;': "'",
// 			'&quot;': '"'
// 		}[tag] || tag)
// );
const unescapeHTML = str => str.replace(/&amp;|&#39;|&quot;/g, tag => ({
		'&amp;': '&',
		'&#39;': "'",
		'&quot;': '"'
	}[tag] || tag)
);

const calcHeight = cached((h) => ({ height: `calc(100vh - ${h})` }));

function moveCursorToEnd(el){
	if(el){
		// hack for no type="text"
		let type = el.type;
		let notext = 0;
		if(type !== "textarea" && type !== "text"){
			el.type = "text";
			notext = 1;
		}

		let len = el.value?.length;
		if (el.setSelectionRange) {
			el.focus();
			el.setSelectionRange(len, len);
		} else if (el.createTextRange) {
			let t = el.createTextRange();
			t.collapse(true);
			t.moveEnd('character', len);
			t.moveStart('character', len);
			t.select();
		}

		if(notext) el.type = type;
	}
}

function isEmpty(value) {
	return (
		value === null || value === undefined || value === '' ||
		(Array.isArray(value) && value.length === 0) || 
		(!(value instanceof Date) && typeof value === 'object' && Object.keys(value).length === 0)
	);
}

function getWidth(el) {
	if (el) {
		let width = el.offsetWidth;
		let style = getComputedStyle(el);

		width -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight) + parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);

		return width;
	}
	return 0;
}

function getHeight(el) {
	if (el) {
		let height = el.offsetHeight;
		let style = getComputedStyle(el);

		height -= parseFloat(style.paddingTop) + parseFloat(style.paddingBottom) + parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);

		return height;
	}
	return 0;
}

function getOuterWidth(el, margin) {
	if (el) {
		let width = el.offsetWidth || el.getBoundingClientRect().width;

		if (margin) {
			let style = getComputedStyle(el);
			width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
		}

		return width;
	}
	return 0;
}

function getOuterHeight(el, margin) {
	if (el) {
		let height = el.offsetHeight || el.getBoundingClientRect().height;

		if (margin) {
			let style = getComputedStyle(el);
			height += parseFloat(style.marginTop) + parseFloat(style.marginBottom);
		}

		return height;
	}
	return 0;
}
const simplifyErrorMsg = (err) =>{    
	let errMsg = err.sourceError?.message?.split(';')?.find(x=>x.includes('Error:'));
	if(errMsg)  {
	  errMsg = errMsg.replace('Error:','');    
	  let tmp = errMsg.split(' ');                
	  return tmp.map(x=>{                  
		try{                    
		  x = x.toLowerCase().includes('finansys_app') ? '' : x ;                    
		  return x.replaceAll('"','');                  
		}catch{                    
		  return x;                  
		}                
	  }).join(' ');
	} else{
	  return "Failed to process query."
	}      
  }

const customEqCellRenderer = (value, column, cellElement, rowElement) => {
	var strValue = value?.toLocaleString();
	var objValue = isJsonString(value) ? JSON.parse(value) : null;
	if (objValue) {
		if (objValue.length) {
			strValue = objValue.map((val) => {
				if (typeof val === "object") {
					if (val.type === "User") {
						return val["fullname"] || val["email_address"];
					}
					return val[Object.keys(val)[0]]?.toLocaleString();
				}
				return val;
			}).join(', ');
		} else if (objValue.type === "User") {
			strValue = objValue["fullname"] || objValue["email_address"];
		} 
		strValue = strValue ?? objValue[Object.keys(objValue)[0]]?.toLocaleString();
	}
	if(strValue?.startsWith('<a') && strValue?.endsWith('</a>')){
		cellElement.innerHTML = strValue;
	}else{
		cellElement.innerText = strValue ?? '';
	}
}

function findDuplicates(array, insensitive = false) {
	const data = insensitive ? array.map(item => item.toLowerCase()) : array
	return [...new Set(data.filter(item => !!item && item !== '""').filter((item, index) => data.indexOf(item) !== index))]
}


const compareArrays = (arr1 = [], arr2 = []) => {
	let diff = [];
	try {
		if (arr1.length > 0 && arr2.length > 0) {
			arr1.forEach((element) => {
				if (
					!arr2.some(
					(e) =>
						JSON.stringify(e.dataField) === JSON.stringify(element.dataField)
					)
				) {
					diff.push(element);
				}
			});
		}
		
		return diff;
	} catch (e) {
		return diff
	}
  };

  const mergeColumns = (defaultData, secondayData) => {
	const merged = defaultData.map(obj => {
		const matchingObj = secondayData?.find(item => item.dataField === obj.dataField);
		if (matchingObj) {
		  return { ...obj, hidden: matchingObj.hidden || false, text: obj.text };
		}
		return obj;
	  });
	return merged
  } 

  const mergeColumnsTable = (defaultData, secondayData) => {
	const data = []
	secondayData?.map(obj => {
		const matchingObj = defaultData?.find(item => item.dataField === obj.dataField);
		if (matchingObj) {
		  data.push({ ...matchingObj, hidden: obj.hidden, text: obj.text, columnWidth: obj.columnWidth });
		}
	});
	return data

  } 

  const mergeReferenceTable = (defaultColumns, configColumns) => {
	let merged = [];
	let unmerged = defaultColumns;
	configColumns?.map(obj => {
		const foundIdx = unmerged?.findIndex(item => item.dataField === obj.dataField);
		if (foundIdx >= 0) {
			merged.push({ 
				...unmerged[foundIdx], 
				hidden: obj.hidden, 
				columnWidth: obj.columnWidth 
			});
			unmerged.splice(foundIdx, 1);
		}
	});
	merged = merged.concat(unmerged);
	return merged;
  }

  const mapGroupOptionsTree = (trees) =>{
	return (trees || []).reduce((result,v) => {
	  if(v.type === "Module" || !v.id || v.id === "#") return result;
	  return [
		...result, {
		  ...v,
		  title: v.text,
		  value: v.id,
		  children: mapGroupOptionsTree(v.children)
		}];
	},[])
  }
export {
	// SUPPORT_LOADING, 
	cached, 
	isStr, isObj, isFunc, isBool, isNum, 
	Cx, domQ, domQall, setClass, toggleClass, hasClass, hasAttr, getAttr, setAttr, 
	Qid, 
	preventQ, 
	// noop, 
	// bindFuncs, 
	omit, 
	makeEl, 
	// newURL, 
	toCapital, 
	getInitials, 
	obj2formData, 
	formikValidClass, 
	getParam, 
	getLocalISOString, 
	isMobile, 
	unescapeHTML, 
	calcHeight, 
	moveCursorToEnd, 
	jsonParse, 
	isEmpty, 
	getWidth, 
	getHeight, 
	getOuterWidth, 
	getOuterHeight, 
	simplifyErrorMsg,
	customEqCellRenderer,
	findDuplicates,
	compareArrays,
	mergeColumns,
	mergeColumnsTable,
	mergeReferenceTable,
	mapGroupOptionsTree
};

