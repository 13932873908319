import React from 'react';// , { useState }
import { ErrorBoundary } from 'react-error-boundary';
import Button from 'react-bootstrap/Button';
// import { Alert } from 'antd';

import Flex from './Flex';
import { Cx } from '@utils/Q';

export default function ErrorComponent({
  className, 
  prefixClass = "alert", 
  type = "light", 
  // children, 
  ...etc 
}){
  // const [explode, setExplode] = useState(false);// Not work for all errors

  return (
    <ErrorBoundary 
      // FallbackComponent={ErrorFallback} 
      fallbackRender={() => ( // { error }
        <Flex 
          dir="column" 
          justify="center" 
          align="center" 
          role="alert" 
          className={
            Cx(prefixClass, 
              "py-5 mb-0 rounded-0 text-break ovauto w-100", {
              ["alert-" + type]: type
            }, className)
          } 
        >
          <h3 className="text-dark">⚠ Something went wrong.</h3>
          {/* <p>{navigator.onLine ? error.message : "Your internet connection is offline."}</p> */}

          {navigator.onLine ? 
            <Button onClick={() => window.location.reload()} variant="outline-primary">Reload</Button>
            : 
            <p className="lead mb-0">Your internet connection is offline.</p>
          }
          
          {/* {navigator.onLine && 
            <Button onClick={() => window.location.reload()} variant="outline-primary">Reload</Button>
          } */}
        </Flex>
      )} 
      onError={(err, info) => {
        // setExplode(true);// Not work for all errors
        console.log('onError Component error: ', err);
        console.log('onError Component info: ', info);
      }} 
      // onReset={() => setExplode(false)} // Not work for all errors
      // resetKeys={[explode]} // Not work for all errors
      {...etc}
    />
  )
}

/*
<Alert 
  showIcon 
  type="error" 
  message="⚠ Something went wrong." 
  description={navigator.onLine ? error.message : "Your internet connection is offline."} 
  action={
    navigator.onLine && 
      <Button onClick={() => window.location.reload()} variant="outline-dark" className="ml-3">Reload</Button>
  }
  className={Cx("text-break ovauto w-100", className)} 
/>
*/

// function ErrorFallback({ error }){ // , resetErrorBoundary
//   return (
//     <Flex 
//       dir="column" 
//       justify="center" 
//       align="center" 
//       role="alert" 
//       className="alert alert-light mb-0 rounded-0 text-break ovauto w-100" 
//     >
//       <h3>⚠ Something went wrong.</h3>
//       <p>{navigator.onLine ? error.message : "Your internet connection is offline."}</p>
      
//       {navigator.onLine && 
//         <Button onClick={() => window.location.reload()} variant="outline-dark" className="ml-3">Reload</Button>
//       }
//     </Flex>
//   )
// }

// {navigator.onLine && 
//   <div>
//     <Button onClick={resetErrorBoundary} className="mr-3">Try again</Button>
//     Or
//     <Button onClick={() => window.location.reload()} variant="outline-dark" className="ml-3">Reload</Button>
//   </div>
// }

// {!explode && children}

// fallbackRender={({ error, resetErrorBoundary }) => (
//   <div role="alert" 
//     className={Cx("alert alert-light mb-0 rounded-0 text-break ovauto", errorClass)} 
//   >&#9888; Something went wrong. 
//     <p>{navigator.onLine ? error.message : "Your internet connection is offline."}</p>
//     <Button onClick={resetErrorBoundary}>Try again</Button> Or 
//     <Button onClick={() => window.location.reload()} variant="outline-dark">Reload</Button>
//   </div>
// )} 
