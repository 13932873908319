import { initRequest } from "@utils/actionMethods";
import { parseJsonString } from "@utils/json";

const setOrganizationPreference = (payload) => {
    return dispatch => dispatch({
        type: 'SET_ORGANIZATION_PREFERENCE',
        payload
    })
}
const getCurrentOrganization = () => {
    return async dispatch => {
        const request = initRequest(dispatch)
        let result = {}
        await request('getOrgTimeZone',null,
            ({ data }) => {
                const { preference } = data || {};
                result = parseJsonString(preference);
            }
        )
        dispatch(setOrganizationPreference(result));        
    }
}
const organizationActions = {
    setOrganizationPreference,
    getCurrentOrganization
}

export default organizationActions