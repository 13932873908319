import useApiRequest from "./useApiRequest";

const useDatasetApi = () => {
    const { request, loading } = useApiRequest()
    const getDatasetList = params => {
        return request('getDatasetList', params)
    }
    const getDataByKey = params => {
        return request('getDataByKey', params)
    }
    const getDataset = params => {
        return request('getDataset', params)
    }
    const saveEntry = params => {
        return request('saveRefDataEntry', params)
    }
    const addEntry = (params) => {
        return request('addEntry', params);
    }
    return {
        loading,
        getDatasetList,
        getDataByKey,
        getDataset,
        addEntry,
        saveEntry
    }
}

export default useDatasetApi