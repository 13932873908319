import React, { forwardRef, useState } from 'react';// { useState, useEffect }
import Button from 'react-bootstrap/Button';

import { Cx } from '../../utils/Q';

const Password = forwardRef(
	({
		wrapClass, 
		className, 
		prepend, 
		append, 
		inputSize, 
		// children, 
		btnProps, 
		variant = "light", 
		...etc
	}, 
	ref
) => {
  const [see, setSee] = useState(false);

	return (
		<label className={Cx("input-group", { ["input-group-" + inputSize]: inputSize }, wrapClass)}>
			{prepend}

			<input 
				{...etc} 
				ref={ref} 
				type={see ? "text":"password"} 

				// OPTION: From Gmail, toggle or default render
				// spellCheck={see ? false : undefined} 
				// autoComplete={see ? "off" : undefined} 
				// autoCapitalize={see ? "off" : undefined} 
				className={Cx("form-control", className)} 
				spellCheck={false} 
				autoComplete="off" 
				autoCapitalize="off" 
			/>

			<div className="input-group-append">
				{append} 

				<Button as="div" tabIndex="-1" 
					{...btnProps} 
					variant={variant} 
					className={Cx("tip tipTR fa-fw fa fa-eye" + (see ? "-slash":""), btnProps?.className)} // btn btn-light 
					aria-label={(see ? "Hide":"Show") + " Password"}
					onClick={() => setSee(!see)} 
				/>
			</div>
		</label>
	);
});

export default Password;

