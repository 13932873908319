export const SIZE_PER_PAGE = 10
export const SIZE_PER_PAGE_LIST = [{
    text: '10 / page', value: 10
  }, {
    text: '20 / page', value: 20
  }, {
    text: '50 / page', value: 50
  }, {
    text: '100 / page', value: 100
  }]
export const STATUS_DRAFT = 'Draft';
export const STATUS_IN_PROGRESS = 'In Progress';
export const STATUS_COMPLETED = 'Completed';
export const STATUS_FAULTED = 'Faulted';
export const STATUS_STARTING = 'Starting';
export const STATUS_RETURNED = 'Returned';
export const WORKFLOW_STATUSES = [
    STATUS_DRAFT,
    STATUS_IN_PROGRESS,
    STATUS_COMPLETED,
    STATUS_FAULTED,
    STATUS_STARTING,
    STATUS_RETURNED,
]
export const DEFAULT_COLUMNS_COUNT = 25