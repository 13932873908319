/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom"; // , Link
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
// import SVG from "react-inlinesvg";

import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import Aroute from "../../../../../app/components/Aroute";
import Ava from "../../../../../app/components/Ava";

export function UserProfileDropdown() {
	const history = useHistory();
	// const { user } = useSelector(state => state.auth);
	const { user, imgUrl, email, isSysAdmin, isAdministrator } = useSelector(
		({ auth }) => ({
			user: auth?.config?.userInfo?.username,
			email: auth?.config?.userInfo?.email,
			isSysAdmin: auth?.config?.isSysAdmin,
			isAdministrator: auth?.config?.isAdministrator,
			imgUrl: "/api/sys/User/ProfilePicture?id=" + auth?.config?.userInfo?.uid + "&x-token=" + auth.authToken,
		}),
		shallowEqual
	);

	const uiService = useHtmlClassService();
	const layoutProps = useMemo(() => {
		return {
			light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
		};
	}, [uiService]);

	return (
		<Dropdown drop="down" alignRight>
			<Dropdown.Toggle
				as={DropdownTopbarItemToggler}
				id="dropdown-toggle-user-profile"
			>
				<div className="btn btn-lg btn-icon w-auto btn-clean btn-dropdown d-flex align-items-center px-2 text-dark">{/* clean */}
					<span className="font-size-base d-none d-md-inline mr-1">Hi,</span>
					<span className="font-weight-bolder font-size-base d-none d-md-inline mr-3">
						{user}
					</span>

					<Ava
						contain
						frameClass="d-block symbol symbol-35 w-35px" //  symbol-light-success
						alt={user}
						src={imgUrl}
						w={35}
						h={35}
					// bg="#C9F7F5" 
					// labelColor="symbol-label" 
					/>
				</div>
			</Dropdown.Toggle>
			<Dropdown.Menu
				className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
			>
				<>
					{/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
					{layoutProps.light && (
						<>
							<div className="d-flex align-items-center p-8 rounded-top">
								<div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
									<img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
								</div>
								<div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{user}</div>
								{/* <span className="label label-light-success label-lg font-weight-bold label-inline">3 messages</span> */}
							</div>
							<div className="separator separator-solid"></div>
						</>
					)}

					{!layoutProps.light && (
						<div
							className="d-flex align-items-center justify-content-between flex-wrap p-7 bg-gradient-main rounded-top" // p-8 bgi-size-cover bgi-no-repeat
						// style={{ backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})` }}
						>
							<Ava
								contain
								frameClass="d-block symbol symbol-50 w-50px mr-3"
								alt={user}
								src={imgUrl}
								w={50}
								h={50}
							/>

							<div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
								{user}

								{email && <div className="small q-mr fal fa-envelope">{email}</div>}
							</div>
						</div>
					)}
				</>

				<div className="navi navi-spacer-x-0 pt-3">
					<Aroute to="/app/user/profile" className="navi-item">
						<div className="navi-link px-8">
							<div className="symbol symbol-40 bg-light mr-3">
								<div className="symbol-label">
									<span className="svg-icon svg-icon-md text-primary fa-lg far fa-user" />
								</div>
							</div>
							<div className="navi-text">
								<div className="font-weight-bold">
									My Profile
								</div>
								<div className="text-muted">
									Account settings and more
								</div>
							</div>
						</div>
					</Aroute>
					{(isSysAdmin || isAdministrator) && <Aroute to="/app/audit" className="navi-item">
						<div className="navi-link px-8">
							<div className="symbol symbol-40 bg-light mr-3">
								<div className="symbol-label">
									<span className="svg-icon svg-icon-md text-primary fa-lg far fa-table" />
								</div>
							</div>
							<div className="navi-text">
								<div className="font-weight-bold">
									Audit Trail
								</div>
								<div className="text-muted">
									Audit record of Additions/creation, Deletions, Amendments
								</div>
							</div>
						</div>
					</Aroute>}

					{isSysAdmin && <Aroute to="/process/dashboard" target="_blank" tar className="navi-item">
						<div className="navi-link px-8">
							<div className="symbol symbol-40 bg-light mr-3">
								<div className="symbol-label">
									<span className="svg-icon svg-icon-md text-primary fa-lg far fa-cogs" />
								</div>
							</div>
							<div className="navi-text">
								<div className="font-weight-bold">Process</div>
								<div className="text-muted">
									Process Engine Definitions, Instances
								</div>
							</div>
						</div>
					</Aroute>}

					

					<div className="navi-footer px-8 py-3 mt-3 border-top">
						<Button onClick={() => history.push("/logout")} variant="light-primary" className="font-weight-bold">Sign out</Button>

						{/* <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
					</div>
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
}

// <div className="navi-separator mt-3" />