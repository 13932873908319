import {STATUS_COMPLETED, STATUS_DRAFT, STATUS_IN_PROGRESS, STATUS_FAULTED, STATUS_STARTING} from "@constants/datatable";
import { isJsonString } from "@utils/json";
import { DEFAULT_COLUMNS_COUNT } from "../../app/constants/datatable";
import { onConvertToJson } from "../requestData";

export const fieldsToColumn = (fields = [], data = [], isDraft = false) => {
    const excludedColumns = ['richtext']
    return [{
        dataField: 'entry-module',
        hidden: true,
    }, 
        // there needs to be at least 1 column defined for the datatable
        ...isDraft
            ? [{
                    dataField: 'workflow_status',
                    text: 'Status',
                    hidden: false,
            }]
            : [{
                dataField: 'workflow_status',
                text: 'Status',
                hidden: false,
                editorType: 'workflow-item-status',
            }, {
                dataField: 'tasks',
                void: true,
                text: '',
                classes: 'text-center w-60px',
                headerClassNames: 'w-60px',
                editorType: 'workflow-item-tasks',
            }, {
                dataField: 'workflow_activity_name',
                text: 'Process Step',
                hidden: false,
                editorType: 'text',
            }, {
                dataField: 'workflow_started_on',
                text: 'Process Started on',
                hidden: false,
                editorType: 'datetime',
            }, {
                dataField: 'workflow_initiator_user',
                text: 'Process Initiator',
                hidden: false,
                editorType: 'text',
            }, {
                dataField: 'workflow_initiator_email',
                text: 'Initiator Email',
                hidden: false,
                editorType: 'text',
            }, {
                dataField: 'workflow_completed_on',
                text: 'Process Completed on',
                hidden: false,
                editorType: 'datetime',
            }],
        ...(fields
            .map((field, index) => {
                let fieldColumns = null
                try {
                    const dataColumns = data?.[0]?.[`${field.id}_columns`]
                    if (isJsonString(dataColumns)) {
                        fieldColumns = {
                            columns: JSON.parse(dataColumns),
                        }
                    }
                } catch (e) {
                    console.error(e)
                }
                return {
                    dataField: field.id,
                    editorType: field.fieldType,
                    text: field.fieldName,
                    icon: field.icon,
                    hidden: index > DEFAULT_COLUMNS_COUNT,
                    config: field,
                    ...fieldColumns
                        ? fieldColumns
                        : {}
                }
            })
            .filter(field => !excludedColumns.includes(field.editorType))
        )
    ]
}

export const dataToColumn = () => {
    return [{
        dataField: 'entry-module',
        hidden: true,
    }, 
    {
        dataField: 'display_name',
        text: 'App Name',
        hidden: false,
    }, {
        dataField: 'activity_title',
        text: 'Process Step',
        hidden: false,
    }, {
        dataField: 'task_type',
        text: 'Task Type',
        hidden: false,
    }, {
        dataField: 'assigned_from_user',
        text: 'Task Source',
        hidden: false,
    }, {
        dataField: 'record_started_by',
        text: 'Started by',
        hidden: false,
    }, {
        dataField: 'started_on',
        text: 'Started at',
        editorType: 'datetime',
        hidden: false,
    }, {
        dataField: 'workflow_status',
        text: 'Status',
        hidden: true,
        editorType: 'workflow-item-status',
    }, {
        dataField: 'workflow_started_on',
        text: 'Process Started on',
        hidden: true,
        editorType: 'datetime',
    }, {
        dataField: 'workflow_initiator_user',
        text: 'Process Initiator',
        hidden: true,
        editorType: 'text',
    }, {
        dataField: 'workflow_initiator_email',
        text: 'Initiator Email',
        hidden: true,
        editorType: 'text',
    }, {
        dataField: 'workflow_completed_on',
        text: 'Process Completed on',
        hidden: true,
        editorType: 'datetime',
    }]
}

export const defaultDraftColumn = () => {
    return [{
        dataField: 'entry-module',
        hidden: true,
    }, {
        dataField: 'workflow_status',
        text: 'Status',
        editorType: 'workflow-item-status',
        hidden: false,
    }]
}

export const defaultOtherColumn = () => {
    return [{
        dataField: 'entry-module',
        hidden: true,
    }, {
        dataField: 'workflow_status',
        text: 'Status',
        hidden: false,
        editorType: 'workflow-item-status',
    }, {
        dataField: 'tasks',
        void: true,
        text: '',
        classes: 'text-center w-60px',
        headerClassNames: 'w-60px',
        editorType: 'workflow-item-tasks',
    }, {
        dataField: 'workflow_activity_name',
        text: 'Process Step',
        hidden: false,
        editorType: 'text',
    }, {
        dataField: 'workflow_started_on',
        text: 'Process Started on',
        hidden: false,
        editorType: 'datetime',
    }, {
        dataField: 'workflow_initiator_user',
        text: 'Process Initiator',
        hidden: false,
        editorType: 'text',
    }, {
        dataField: 'workflow_initiator_email',
        text: 'Initiator Email',
        hidden: false,
        editorType: 'text',
    }, {
        dataField: 'workflow_completed_on',
        text: 'Process Completed on',
        hidden: false,
        editorType: 'datetime',
    }]
}

export const ocrDocumentColumn = () => {
    return {
        id:  "_ocr_file_path",
        dataField: "_ocr_file_path",
        fieldName: "View Document",
        editorType: "file",
        fieldType: "file",
        text: "View Document",
        label: "View Document",
        hidden: false
    }
}

export const flowToElements = instances => {
    const { activities = [], connections = [] } = instances

    return activities.filter(act => {
        return act?.type && act?.type !== 'default'
    }).map(act => {
        const { state, left, top, displayName, ...activity } = act
        const { title, name } = state
        const activityTypeMap = {
            If: 'IfElse',
        }
        return {
            ...activity,
            type: activityTypeMap[activity?.type] || activity?.type,
            position: {
                x: left,
                y: top,
            },
            data: {
                label: displayName || name || title || act.type,
                raw: state,
            },
        }
    }).concat(connections.map(conn => {
        const { sourceActivityId, destinationActivityId, targetActivityId, outcome, state } = conn
        return {
            ...state,
            sourceHandle: outcome,
            source: sourceActivityId,
            target: targetActivityId || destinationActivityId,
            arrowHeadType: state.arrowHeadType || 'arrowclosed',
        }
    }))
}

export const mapWorkflowHistory = workflow_history => {
    let assignmentWorkflow = workflow_history.filter((item)=>{
        return (item.activityType==='Assignment') || ['StartProcess','CompletedProcess'].includes(item.activityType)
    });
    return assignmentWorkflow.map((item)=>{
            let results = item.data?.Results?.[0] || item.data?.Results;
            let executeAssignment = item.data?.Assignee?.map((a)=>{ return a.approver_name});
            let performedBy = results?.author_name || '';
            let actionType = item.data?.ActionType || '';
            let message = results?.message;
            let resumeAssignment = item?.data?.ApprovalHistory?.assigned_to?.map(v => v.approver_name)
            let reassignAssignment = item.data?.ApprovalHistory?.reassigned_to?.map((a)=>{ return a.to.approver_name})
            const reassignStart =  item.data?.ApprovalHistory?.reAssignedTo?.map((a)=>{ return a.to.approver_name})
            const approvedBy = item?.data?.ApprovalHistory?.current_approver?.approver_name
            return {
                ...item,
                initiatedBy: item.initiatedBy,
                executeAssignment: executeAssignment?.length ? executeAssignment.join(', '):'',
                resumeAssignment: resumeAssignment?.length ? resumeAssignment.join(', ') : '',
                outcome : item.data?.Outcomes?.[0] || '',
                variant: item.activityType==='StartProcess'?'start':item.activityType==='CompletedProcess'?'step':'goto',
                performedBy,
                message,
                approvedBy,
                actionType,
                reassignAssignment,
                reassignStart
            };    
    })
}
export const mapRecordStatus = item => {
    let status
    switch (true) {
        case Boolean(item.workflow_status === STATUS_FAULTED):
            status = STATUS_FAULTED
            break
        case Boolean(item.workflow_completed_on):
            status = STATUS_COMPLETED
            break
        case Boolean(item.workflow_started_on) &&
        item.workflow_faulted_on === null &&
        item.workflow_completed_on === null:
            status = STATUS_IN_PROGRESS
            break
        case item.workflow_instance_id === null &&
        item.workflow_started_on === null:
        default:
            status = STATUS_DRAFT
            break
    }
    return {
        ...item,
        status,
    }
}

export const mapRecordDisplayStatus = item => {
    const finalStatuses = [
        STATUS_COMPLETED,
        STATUS_STARTING,
    ]
    return {
        ...item,
        status: `${item.status}${finalStatuses.includes(item.workflow_status) || !item.workflow_status ? '' : ` - ${item.workflow_status}`}`,
    }
}

export const elsaWorkflowToReactFlow = flow => {
    return {
        ...flow,
        activities: flow.activities.map(({activityId, ...act}) => ({
            ...act,
            id: act.id || activityId,
            type: act.type === 'Finish'? 'FinishDataconnector': act.type,
        })),
        connections: flow.connections.map(({targetActivityId, ...conn}) => ({
            ...conn,
            destinationActivityId: conn.destinationActivityId || targetActivityId,
        }))
    }
}

export const xmlToJson = async response => {
    // onConvertToJson return stringified JSON object from the xml result
    // it is wrapped within a top level property.
    // this following line extract values from the object.
    try {
        const isXML = (response?.data || '').match(`<result>`)
        const [rawData] = isXML
            ? Object.values(
                JSON.parse(
                    await onConvertToJson(response?.data)
                )
            )
            : response?.data
        return rawData
    } catch {}
}

export const mapSidebarModules = (dataSidebar, search = false) => {
    const sidebar = []
    const filterSidebar = dataSidebar && Object.keys(dataSidebar).length && Object.keys(dataSidebar).filter((data) => data !== "Favourites")
    filterSidebar.map((item, key) => {
      const nameCategory = item;
      const dataKey = nameCategory + key;
      const dataMenu = !search ? dataSidebar?.[item] : (dataSidebar?.[item] || [])?.filter(search);
      const categoryId = () => {
        if (dataMenu?.length > 0 && dataMenu?.[0]?.application_module_category_id !== null) {
          return dataMenu?.[0]?.application_module_category_id
        }
        return dataKey
      }
      const categoryIcon = () => {
        if (dataMenu?.length > 0 && dataMenu?.[0]?.category_icon !== null) {
          return dataMenu?.[0]?.category_icon
        } else {
          if (nameCategory === "Favourites") {
            return "navicon-Favourites"
          }
          return "fa-list-ul"
        }
      }
      if (dataMenu.length > 0) {
        sidebar.push({
          name: nameCategory,
          icon: categoryIcon(),
          id: categoryId(),
          children: !search ? dataMenu : dataMenu.filter(search)
        })
      }
    })
    return sidebar
}
export const mapSidebarMenuTrees = (dataSidebar, searchTerm) => {
    const sidebar = [];
    try{
    dataSidebar.forEach((item) => {
        const match = searchTerm ? item.text?.toLowerCase().includes(searchTerm.toLowerCase()) : true;
        const matchedChildren = match ? mapSidebarMenuTrees(item.children) : mapSidebarMenuTrees(item.children, searchTerm);
        if (item.type === "Module" && match) {
            sidebar.push(item);
        } else if (matchedChildren?.length) {
            sidebar.push({
                ...item,
                children: matchedChildren
            })
        }
    })
    } catch (e) { console.error(e.message) }
    return sidebar
}

export default {
    fieldsToColumn,
    dataToColumn,
    flowToElements,
    mapRecordStatus,
    mapWorkflowHistory,
    elsaWorkflowToReactFlow,
    mapSidebarModules,
    mapSidebarMenuTrees
}
