import { useQuery, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "../hooks/useApiRequest";

export const fetchDataTable = (path, params, request) => {
    if (params) {
        return request(path, params)
    }
    return null
}

export const getDataTableQueryKey = (path, params) => path && params ? [path, params] : []

export const useDataTableQuery = (path, params) => {
    const { request } = useApiRequest()
    const queryKey = getDataTableQueryKey(path, params)
    return useQuery(
        queryKey, 
        () => fetchDataTable(path, params, request),
        {
            suspense: true,
            enabled: typeof params !== undefined,
        }
    )
}

export const useOptimisticDataTableQuery = (path, params) => {
    const queryClient = useQueryClient()
    const queryKey = getDataTableQueryKey(path, params)
    return data => queryClient.setQueryData(queryKey, data)
}

export const useInvalidateModuleDatatableQueries = (moduleId) => {
    const queryClient = useQueryClient()
    return () => {
        queryClient.invalidateQueries({
            predicate: query => {
                return (query.queryKey[0] || '').match(/getModuleTasksDynamic|getDrafts|getModuleItems|getTasksByUser|getTaskScheduler/) && 
                    query.queryKey[1]?.moduleId === moduleId
            }
        })
        queryClient.invalidateQueries(['module-summary', moduleId])
        queryClient.invalidateQueries(['module-history', moduleId])
        queryClient.invalidateQueries(['module-record', moduleId])
    }
}

export const useInvalidateAllModuleDatatableQueries = () => {
    const queryClient = useQueryClient()
    return () => {
        queryClient.invalidateQueries({
            predicate: query => {
                return (query.queryKey[0] || '').match(/getModuleTasksDynamic|getDrafts|getModuleItems|getTaskScheduler/)
            }
        })
        queryClient.invalidateQueries(['module-summary'])
        queryClient.invalidateQueries(['module-history'])
        queryClient.invalidateQueries(['module-record'])
    }
}