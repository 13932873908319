
const initialState = {
    time_zone: null,
    date_format: 'YYYY-MM-DD',
    datetime_format: 'YYYY-MM-DD HH:mm:ss',
    time_format: 'HH:mm:ss'
}

const timezoneReducer = (state = initialState, action) => {
    const {date_format, time_zone} = action.payload || {};
    switch (action.type) {
        case 'SET_TIME_ZONE':
            const timeZone = time_zone ?? state.time_zone;
            const dateFormat = date_format ?? state.date_format;

            localStorage.setItem('fsa-time-zone', JSON.stringify({
                ...state,
                time_zone: timeZone,
                date_format: dateFormat,
                datetime_format: dateFormat + ' HH:mm:ss',
                time_format: 'HH:mm:ss'   
            }))
            return {
                ...state,
                time_zone: timeZone,
                date_format: dateFormat,
                datetime_format: dateFormat + ' HH:mm:ss',
                time_format: 'HH:mm:ss'   
            }
        default:
            localStorage.setItem('fsa-time-zone', JSON.stringify({
                ...state   
            }))
            return { ...state }
    }
}

export default timezoneReducer