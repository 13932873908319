import React, { forwardRef } from 'react';
// import { mapToCssModules } from './utils';
import { Cx } from '../../utils/Q';

// cssModule
const Flex = forwardRef(
	({
		As = "div", 
		inline = false, 
		hidden = false,
		dir, 
		wrap, 
		nowrap, 
		justify, 
		align, 
		self, 
		fill, 
		grow, 
		shrink, 
		content, 
		className, 
		...etc
	}, 
	ref
) => {
	return (
		<As 
			{...etc} 
			ref={ref} 
			className={
				Cx(hidden ? 'd-none' : "d-" + (inline ? "inline-" : "") + "flex", {
					"flex-wrap" :  wrap && !nowrap,
					"flex-nowrap" :  nowrap && !wrap,
					["flex-" + dir] : dir,
					["justify-content-" + justify] : justify,
					["align-items-" + align] : align, 
					
					["align-content-" + content] : content, // OPTION 
					
					// OPTION:
					["align-self-" + self] : self,
					"flex-fill" : fill,
					["flex-grow-" + grow] : grow,
					["flex-shrink-" + shrink] : shrink
				}, className)
			} 
		/>
	);
});

export default Flex;

// Flex.defaultProps = {
// 	As: 'div',
// 	inline: false
// };

/* Flex.propTypes = {
	// as: P.oneOfType([P.func, P.string]),
	// type: P.string,
	dir: P.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
	justify: P.oneOf(['start', 'end', 'center', 'between', 'around', 'even', 'stretch']),
	align: P.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
	className: P.string,
	cssModule: P.object
}; */
