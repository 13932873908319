const timezoneEndpoints = {
    getOrgTimeZone() {
        return {
            url: '/api/sys/organization/current',
            method: 'GET'
        }
    },
    setUserTimeZone({ data }) {
        return {
            url: '/api/identity/manageauthenticator/setusertimezone',
            method: 'POST',
            data
        }
    },
    setOrgTimeZone({ data }) {
        return {
            url: '/api/sys/organization/setorganizationtimezone',
            method: 'POST',
            data
        }
    },
    getTimeZones() {
        return {
            url: '/api/identity/manageauthenticator/timezone?iana=true',
            method: 'GET'
        }
    },
}

export default timezoneEndpoints