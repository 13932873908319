import React, { forwardRef } from 'react';// , { useState }

import { Cx, setClass } from '../../utils/Q';

const Img = forwardRef(
	({
    	alt = "", 
		loading = "lazy",
		draggable = false, 
		wrapAs = "div", 
		onClick = null,
		wrapProps, 
    	w, h, 
		fluid, thumb, circle, round, 
		className, frameClass, 
		frame, 
		frameClassPrefix = "img-frame", 
		caption, captionClass, 
		onLoad, onError,
		children, 
		...etc
	}, 
	ref
) => {
	const LOAD_CX = "ant-skeleton-avatar";
	const bsFigure = frame === "bs";
	const As = bsFigure ? "figure" : wrapAs;
	
	const setCx = et => {
		setClass(et, LOAD_CX, "remove"); // bg-secondary
	}
	
	const Load = e => {
		setCx(e.target);
		if(onLoad) onLoad(e);
	}
	
	const Error = e => {
		const et = e.target;
		
		setCx(et);
		
		// DEV: 
		// et.src = "data:image/svg+xml,%3Csvg width='100%25' height='150' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid slice' style='font-family:sans-serif;font-size:1.125rem;text-anchor:middle'%3E%3Crect width='100%25' height='100%25' fill='%23b3d6f4'%3E%3C/rect%3E%3Ctext x='50%25' y='50%25' fill='%23777' dy='.3em'%3E" + alt + "%3C/text%3E%3C/svg%3E";
		et.src = "/media/misc/img_err.svg";

		if(onError) onError(e);
		
		return;// null
	}

	const img = () => (
		<img 
			{...etc}
      		ref={ref} 
      		loading={loading} 
			alt={alt}
			width={w} 
			height={h} 
			className={
				Cx(LOAD_CX, { // bg-secondary
         			'img-fluid': fluid, 
					'img-thumbnail': thumb,
					'rounded-circle': circle,
					'rounded': round,
					'figure-img': bsFigure
				}, className)
			}
			draggable={draggable} 
			onError={Error} 
			onLoad={Load} 
		/>
	);
	
	if(frame){
		return (
			<As 
				{...wrapProps} 
				className={
					Cx("ant-skeleton ant-skeleton-active", frameClassPrefix, {
						"figure": bsFigure
					}, frameClass)
				}
				onClick={onClick}
				style={{cursor: 'pointer'}}
			>
				{img()}

				{(bsFigure && caption) && <figcaption className={Cx("figure-caption", captionClass)}>{caption}</figcaption>}

				{children}
			</As>
		)
	}

  return img();
});

export default Img;
