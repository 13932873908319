import { useEffect, useState } from 'react';
import { message as _message } from 'antd';
import axios from 'axios';

const API = "/api/Notification";

const clearNotifBadge = (sub) => {
	const btnNotif = document.getElementById("kt_quick_notifications_toggle");
	if (btnNotif) {
		const badge = btnNotif.querySelector(".badge");
		if (badge) {
			const notif = badge.innerText;
			badge.innerText = sub ? Number(notif) - 1 : "";
		}
	}
}

const onReadDetail = (isRead, ID) => {
	if (!isRead) {
		axios.post(API + "/read?id=" + ID)
			.then(r => {
				// console.log('onReadDetail r: ', r);
				if (r.data && r.data.success) clearNotifBadge(true);
			})
			.catch(e => console.log(e));
	}
}

export default function useGetNotification(ID) {
	const [load, setLoad] = useState(true);
	const [notifs, setNotifs] = useState({ notif: [] });
	const [detail, setDetail] = useState({});
	const [page, setPage] = useState(1);

	const loadMoreData = () => {
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		setNotifs({ ...notifs, notif: notifs.notif.concat([...new Array(10)].map(() => ({ loading: true }))) });
		
		axios.get(API, { cancelToken: source.token, params: { page, lenght: 10 } })
			.then(res => {
				const { success, message, data: { notif } } = res?.data || {};
				if (success) {
					setNotifs({ ...notifs, notif: [...notifs.notif, ...notif] });
					setPage(page + 1);
				} else {
					_message.error(message);
				}
			}).catch(e => {
				// console.log('catch e: ', e);
				_message.error(e.message);
				if (axios.isCancel(e)) {
					console.log('Request canceled: ', e.message);
				}
			}).then(() => setLoad(false));
	}

	useEffect(() => {
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		axios.get(API, { cancelToken: source.token })
			.then(res => {
				const { success, data } = res?.data || {};
				// console.log('data: ', data);
				if (success && data) {
					setNotifs(data);
					setPage(page + 1);
					if (ID) {
						const notifDetail = data.notif.find(f => f.id === ID);
						// console.log('notifDetail: ', notifDetail);
						if (notifDetail) {
							onReadDetail(notifDetail.read_on, ID);
							setDetail(notifDetail);
						}
					}
				}
			}).catch(e => {
				// console.log('catch e: ', e);
				if (axios.isCancel(e)) {
					console.log('Request canceled: ', e.message);
				}
			})
			.then(() => setLoad(false));

		return () => {
			source.cancel();// cancel the request (the message parameter is optional)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDetail = (v) => {
		console.log(v);
		onReadDetail(v.read_on, v.id);
		setDetail(v);
		setNotifs({
			...notifs,
			notif: (notifs.notif || []).map(item => (item.id === v.id ? { ...item, read_on: true } : item))
		});
	}

	const onMarkReadAll = () => {
		return new Promise((resolve, reject) => {
			axios.post(API + "/MarkAllAsRead")
				.then(r => {
					if (r.data && r.data.success) {
						document.querySelectorAll(".notifItem").forEach(el => el.classList.remove("unviewed"));
						clearNotifBadge();
						resolve(true);
					} else if (!r.data.success) {
						reject(false);
					}
				})
				.catch(e => console.log(e));
		});
	}

	return {
		load,
		notifs,
		detail,
		onDetail,
		onMarkReadAll,
		loadMoreData
	}
}
