export const operatorMap = (op) => {
    const map = {
        'Equal to': 'eq',
        'Not equal to': 'neq',
        'Is null': 'isnull',
        'Is not null': 'isnotnull',
        'Less than': 'lt',
        'Less than or equal to': 'lte',
        'Greater than': 'gt',
        'Greater than or equal to': 'gte',
        'Starts with': 'startswith',
        'Ends with': 'endswith',
        'Contains': 'contains',
        'Does not contain': 'doesnotcontain',
        'Min length': 'minlength',
        'Max length': 'maxlength',
        'Part of': 'partof',
        'Not part of': 'notpartof',
        'Is empty': 'isempty',
        'Is not empty': 'isnotempty',
        'In': 'isin',
    }
    return map[op]
}

export default { operatorMap }