import formEditorEndpoints from "./form-editor";
import workflowEndpoints from "./workflow";
import workflowDefinitionEndpoints from "./workflow-definition";
import optionsEndpoints from "./options";
import entryModuleEndpoints from "./entry-module";
import taskSchedulerEndpoints from "./task-scheduler";
import taskEndpoints from "./task";
import approvalSignalEndpoints from "./approval-signal";
import datasetEndpoints from "./dataset";
import dataConnectorEndpoints from "./data-connector";
import timezoneEndpoints from "./timezone";
import textractEndpoints from "./textract";
import userPreferenceEndpoints from "./user-preference";

const elsaEndpoint = {
    getElsaPage() {
        return {
            url: `/elsa/dashboard/`,
            method: 'GET'
        }
    }
}

const auditTrailEndpoint = {
    getEntity() {
        return {
            url: "/audittrail/entity/get",
        }
    }
}
const endpoints = {
    ...formEditorEndpoints,
    ...workflowEndpoints,
    ...workflowDefinitionEndpoints,
    ...optionsEndpoints,
    ...entryModuleEndpoints,
    ...taskSchedulerEndpoints,
    ...taskEndpoints,
    ...approvalSignalEndpoints,
    ...datasetEndpoints,
    ...dataConnectorEndpoints,
    ...timezoneEndpoints,
    ...elsaEndpoint,
    ...textractEndpoints,
    ...auditTrailEndpoint,
    ...userPreferenceEndpoints
}

export default endpoints