import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { appMenuSlice } from "../app/modules/ApplicationMenu/_redux/applicationMenuSlice";
import formEditorReducer from "./reducers/formEditorReducer";
import workflowReducer from "./reducers/workflowReducer";
import optionsReducer from "./reducers/optionsReducer";
import timezoneReducer from "./reducers/timezoneReducer";
import organizationReducer from "./reducers/organizationReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  applicationMenu: appMenuSlice.reducer,
  formEditorReducer,
  workflowReducer,
  optionsReducer,
  timezoneReducer,
  organization: organizationReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
