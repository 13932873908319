import React, { createContext, useReducer } from "react";

export const upsertObject = (targetArray = [], index, value) => {
    if (targetArray[index]) {
        targetArray.splice(index, 1, {
            ...targetArray[index],
            ...value,
        })
    } else {
        targetArray.push(value)
    }
    return targetArray
}

export const stateMapper = (payload, state) => {
    const { key, index, value } = payload
    const hasIndex = index !== undefined
    if (hasIndex) {
        return {
            ...state,
            [key]: upsertObject(state[key], index, value),
        }
    }
    
    return {
        ...state,
        ...value,
    }
}

const initialState = {
  visibility: {},
};

const reducer = (state, action) => {
  switch(action.type) {
    case 'UPDATE_VISIBILITY':
      return {
        ...state,
        visibility: stateMapper(
            action.payload,
            state.visibility,
        ),
      };

    default:
      return state;
  }
};

export const VisibilityContext = createContext()
export const VisibilityProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const updateVisibility = payload => {
        dispatch({
            type: 'UPDATE_VISIBILITY',
            payload,
        })
    }
    return (
        <VisibilityContext.Provider value={{ visibilityState: state.visibility, updateVisibility }}>
            { children }
        </VisibilityContext.Provider>
    )
}
