const initialState = {
    loading: {},
    workflow: {},
    editWorkflow: null,
    permissibleFields: [],
    workflowSchemas: {},
    activities: [],
    connections: [],
    sourceActivityId: null,
    workflowInstanceDetails: {},
    assignee: [],
}

const workflowReducer = (state = initialState, action) => {
    const { key, status, moduleId, workflowId, schema, details } = action.payload || {}

    switch (action.type) {
        case 'SET_LOADING':
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [key]: status,
                }
            }
        case 'SET_WORKFLOW':
            return {
                ...state,
                workflow: action.payload,
            }
        case 'SET_ACTIVITIES':
            return {
                ...state,
                activities: action.payload,
            }
        case 'SET_CONNECTIONS':
            return {
                ...state,
                connections: action.payload,
            }
        case 'SET_EDIT_WORKFLOW':
            return {
                ...state,
                editWorkflow: action.payload,
            }
        case 'SET_PERMISSIBLE_FIELDS':
            return {
                ...state,
                permissibleFields: action.payload,
            }
        case 'SET_WORKFLOW_SCHEMA':
            return {
                ...state,
                workflowSchemas: {
                    ...state.workflowSchemas,
                    [moduleId]: schema,
                },
            }
        case 'SET_SOURCE_ACTIVITY_ID':
            return {
                ...state,
                sourceActivityId: action.payload,
            }
        case 'SET_WORKFLOW_INSTANCE_DETAILS':
            return {
                ...state,
                workflowInstanceDetails: {
                    ...state.workflowInstanceDetails,
                    [workflowId]: details,
                },
            }
        case 'SET_ASSIGNEE':
            return {
                ...state,
                assignee: action.payload,
            }
        default:
            return { ...state }
    }
}

export default workflowReducer