// 
// const COMPANY_NAME = 'FinanSys';
const APP_TODAY = new Date()
const APP_NAME = "UniFi";
const APP_YEAR = APP_TODAY.getFullYear();

const FOOTER_LINKS = [
  { href: "https://finansys.com/privacy", label: "Privacy" },
  // { href: "/legal", label: "Legal" },
  { href: "https://finansys.com/contact", label: "Contact" }
];

export {
  // COMPANY_NAME, 
  APP_NAME, 
  APP_YEAR,
  FOOTER_LINKS, 
}
