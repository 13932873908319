const entryModuleEndpoints = {
    saveEntry({ moduleId, data }) {
        return {
            url: '/api/EntryModule/save',
            method: 'POST',
            params: {
                moduleId,
            },
            data,
        }
    },
    getModuleRecord({ moduleId, recordId }) {
        return {
            url: `/api/EntryModule/${recordId}/detail`,
            method: 'GET',
            params: {
                moduleId,
            },
        }
    },
    getModuleSummary({ moduleId, userId }) {
        return {
            url: `/api/entrymodule/summary/${moduleId}`,
            method: 'GET',
            params: {
                userId,
            },
        }
    },
    sendComment({ moduleId, recordId, taskId, data }) {
        return {
            url: `/api/EntryModule/sendComment`,
            method: 'POST',
            params: {
                moduleId,
                recordId,
                taskId
            },
            data           
        }
    },
    getOldComments({ moduleId, recordId }) {
        return {
            url: `/api/EntryModule/${recordId}/comments`,
            method: 'GET',
            params: {
                moduleId
            },
        }
    },
    loadComments({ recordId }) {
        return {
            url: `/api/EntryModule/loadcomment`,
            method: 'GET',
            params: {
                recordId
            },
        }
    },
    submitEntry({ moduleId, recordId, data, taskId }) {
        return {
            url: '/api/EntryModule/submit',
            method: 'POST',
            params: {
                moduleId,
                recordId: recordId || data?.id,
                taskId 
            },
            data,
        }
    },
    restartProcess({ moduleId, recordId }) {
        return {
            url: '/api/EntryModule/workflow/restart',
            method: 'POST',
            params: {
                moduleId,
                recordId,
                'api-version': '2.2.0',
            },
        }
    },
    deleteEntry({ moduleId, recordId: id }) {
        return {
            url: '/api/EntryModule/deletebyId',
            method: 'POST',
            params: {
                moduleId,
                id,
            },
        }
    },
    deleteEntries({ moduleId, recordIds }) {
        return {
            url: '/api/EntryModule/deletebyIds',
            method: 'POST',
            params: {
                moduleId,
            },
            data: recordIds,
        }
    },
    getModuleItems({ moduleId, data }) {
        return {
            url: '/api/EntryModule/datatables',
            method: 'POST',
            params: {
                moduleId,
            },
            data,
        }
    },
    getDrafts({ moduleId, data }) {
        return {
            url: '/api/EntryModule/draftdatatables',
            method: 'POST',
            params: {
                moduleId,
            },
            data,
        }
    },
    submitTask({ taskId }) {
        return {
            url: '/api/EntryModule/approve',
            method: 'POST',
            data: {
                task_id: taskId,
                accepted: true
            },
        }
    },
    rejectTask({ taskId, description }) {
        return {
            url: '/api/EntryModule/approve',
            method: 'POST',
            data: {
                task_id: taskId,
                accepted: false,
                description,
            },
        }
    },
    reassignTask({ taskId, assignTo, comments }) {
        return {
            url: '/api/EntryModule/reassignment',
            method: 'POST',
            params: {
                taskId,
                assignTo
            },
            data:{                
                message: comments
            }
        }
    },
    reassignMultiTasks({ taskIds, assigneeData }) {
        const { assignTo, comments } = assigneeData
        return {
            url: '/api/EntryModule/reassignmentByAdmin',
            method: 'POST',
            params: {
                assignTo: assignTo?.value,
                ...comments ? { message: comments } : {},
            },
            data: taskIds
        }
    },
    reassignMultiTasksInApp({ taskIds, assigneeData }) {
        const { assignTo, comments, moduleId } = assigneeData
        return {
            url: '/api/EntryModule/reassigntasks',
            method: 'POST',
            data: {
                taskId: taskIds,
                moduleId,
                assignTo: assignTo?.value,
                ...comments ? { message: comments } : {},
            }
        }
    },
    withdrawTask({ moduleId, recordId }) {
        return {
            url: '/api/EntryModule/withdraw',
            method: 'POST',
            params: {
                moduleId,
                recordId
            }
        }
    },
    sendBackTask({ taskId, goBackToActivityId, message }) {
        return {
            url: '/api/EntryModule/goback',
            method: 'POST',
            params: {
                taskId,
                goBackToActivityId,
            },
            data:{
                message: message
            }
        }
    },
    getGovHeaders() {
        return {
            url: '/api/EntryModule/govheaders',
            method: 'GET',
        }
    },
    getWorkflowHistory({ moduleId, recordId }) {
        return {
            url: `/api/EntryModule/${moduleId}/workflowhistory`,
            method: 'GET',
            params: {
                recordId,
            },
        }
    }
}

export default entryModuleEndpoints