import React from "react";
import { useField } from "formik";
import Select from "react-select";
import { Switch, TreeSelect, Skeleton } from 'antd';
import Form from "react-bootstrap/Form";

export default function RenderField(prop){
    const [field, meta, helpers] = useField(prop);
    const { touched, error } = meta;
    const typeProp = prop.type === 'text' ? { type: 'text' } : { as: prop.type };
    const onSelect = selected => {
        if(selected){
            if (prop.isMulti) {
                const selectedValues = selected.map(({ value }) => value);
                helpers.setValue(selectedValues);
            } else {
                helpers.setValue(selected.value)
            }
        }else{
            helpers.setValue(null)
        }
    };
    const selectedValue = (prop.options || []).filter(({ value }) => {
        return (field.value || []).includes(value) || field.value === value
    });

    const onChecked = (checked) => {
        helpers.setValue(checked)
    }

    const renderInput = () => {
        const {isLoading, ...fieldProp} = prop;
        if (isLoading) return <Skeleton.Input active s className="d-block w-100" />        
        switch (prop.type) {
            case 'dropdown':
                return (
                    <Select
                        {...prop}
                        onChange={onSelect}
                        value={selectedValue}
                        isClearable={true}
                    />
                )
            case 'treeselect' :
                return (
                    <TreeSelect
                        {...fieldProp}
                        treeData={prop.options}
                        onChange={helpers.setValue}
                        value={field.value}
                        style={{ width:'100%'}}
                        showSearch
                        treeNodeFilterProp={"title"}
                        placeholder="Select..."
                        allowClear
                    />
                )
            case 'switch':
                const { label, ...tempProp } = prop
                return (
                    <Switch
                        onChange={onChecked}
                        {...tempProp}
                    />
                )
            default:
                return (
                    <Form.Control
                        isInvalid={touched && error}
                        {...prop}
                        {...field}
                        {...typeProp}
                    />
                )
        }
    }

    return (
        <div className="row">
            <label className="col-md-3 col-form-label">
                {prop.label} {prop.required && <sup className="font-weight-bold text-danger"> *</sup>}
            </label>

            <div className="col-md-9">
                {renderInput()}
                {(touched && error) && 
                    <Form.Control.Feedback type="invalid">
                        { error }
                    </Form.Control.Feedback>
                }
            </div>
        </div>
    );
}
