import { permissionCodes } from "../adapter"
import { VISIBLE, EDITABLE, READONLY, HIDDEN } from "@constants/permission/values";

export const handleNoSecurityRules = ({ permission, config }) => {
    const { key, type, name, defaultEditability } = config
    const excludeControls = (key === 'visibility'
        ? ['visible', 'hidden']
        : ['editable', 'readonly']).map(code => permissionCodes.findIndex(c => c === code))
    return permission.map(p => {
        let conditions = type === 'field'
            ? p.fields
            : p.fieldGroups
        conditions = (conditions || []).filter(f => {
            return (f.name !== name) || !excludeControls.includes(f?.control)
        })

        // 2: Read-Only
        if (
            defaultEditability === permissionCodes[2] &&
            conditions.findIndex(c => c?.name === name) < 0
        ) {
            conditions.push({
                name,
                control: 2,
            })
        }
        
        return {
            ...p,
            ...type === 'field'
                ? {
                    fields: conditions,
                    fieldGroups: p.fieldGroups || [],
                }
                : {
                    fieldGroups: conditions,
                    fields: p.fields || [],
                },
        }
    })
}
export const handleSecurityRules = ({ permission, config,  rules }) => {
     const {
        defaultVisibility,
        defaultEditability,
        name,
        key,
        type,
        values
    } = config
    const rawPermission = values?.rawPermission || {}
    const mapControls = {
        visibility: defaultVisibility === 'visible' ? 'hidden' : 'visible',
        editability: defaultEditability === 'editable' ? 'readonly' : 'editable',
    }
    const mapDefaultControls = {
        visibility: defaultVisibility,
        editability: defaultEditability,
    }    
    const evaluateRawVisibility = (activityId) =>{
        const visibilityRules = rawPermission?.[name]?.visibility?.root?.rules;
        const defaultVisibilityControl = permissionCodes.findIndex(c => { return c === defaultVisibility});
        const ruleFound = visibilityRules?.find(rl => rl?.value === activityId);
        const result = ruleFound ? (defaultVisibility === 'visible' ? HIDDEN : VISIBLE) : defaultVisibilityControl;
        return result;
    }
    const evaluateRawEditability = (activityId) =>{
        const editabilityRules = rawPermission?.[name]?.editability?.root?.rules;
        const defaultEditabilityControl = permissionCodes.findIndex(c => { return c === defaultEditability});
        const ruleFound = editabilityRules?.find(rl => rl?.value === activityId);
        const result = ruleFound ? (defaultEditability === 'editable' ? READONLY : EDITABLE) : defaultEditabilityControl;
        return result;
    }
    const populateControl = (activityId) => {
        const ruleFound = rules?.find(rl => rl?.value === activityId);        
        const defaultControl = permissionCodes.findIndex(c => { return c === mapDefaultControls[key]});
        let result = ruleFound ? permissionCodes.findIndex(c => { return c === mapControls[key]}) : defaultControl;
        if(key === "editability"){
            const currentVisibility = evaluateRawVisibility(activityId);
            //hidden condition should remain hidden regardless of editability rules
            result = currentVisibility === HIDDEN ? HIDDEN : result;
        }else if(key === "visibility"){
            const currentEditability = evaluateRawEditability(activityId);
            //visible (0) condition should not overwrite readonly (2) condition
            result = result > currentEditability ? result : currentEditability;
        }
        return result;
    }
    
    return permission.map(p => {
        const { fields = [], activityId, fieldGroups = [] } = p
        let conditions = type === 'field'
            ? fields
            : fieldGroups
        if (conditions.length === 0 || !conditions.find(c => c?.name === name)) {
            conditions.push({ name })
        }
        conditions = conditions.map((f) => {
            if (f?.name !== name) {
                return f
            }
            return {
                name: f?.name,
                control: populateControl(activityId)
            }
        }).filter( c => c.control);
        return {
            activityId: activityId || 'fs-complete',
            ...type === 'field'
                ? {
                    fields: conditions,
                    fieldGroups: fieldGroups || [],
                }
                : {
                    fieldGroups: conditions,
                    fields: fields || [],
                },
        }
    })
}
