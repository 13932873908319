/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { shallowEqual, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { Cx } from "@utils/Q";
import style from "./style.module.scss";

export function QuickActionsDropdown() {
  // const bgImage = toAbsoluteUrl("/media/misc/bg-2.jpg");
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.quick-actions.layout") === "offcanvas",
    };
  }, [uiService]);

  const { menus } = useSelector(
    ({ auth }) => ({
      menus: (auth.config?.appMenu?.lists || []).filter((val) => val.href === "/settings"),
    }),
    shallowEqual
  );

  const renderPanel = (menu) => {
    return menu?.items?.length > 0
      ? (
        <Dropdown key={menu.id} drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_actions_panel_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="quick-actions-tooltip">{menu.title}</Tooltip>}
            >
              <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
                <span className="svg-icon svg-icon-xl fi-primary fa-lg far fa-cog">
                  {/* <SVG title=" " src={toAbsoluteUrl(menu.icon)} /> svg-icon-primary */}
                </span>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>
  
          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
            <form key={menu.id}>
              <div 
                className="d-flex flex-column align-items-center justify-content-center py-7 bg-gradient-main rounded-top" // pt-5 pb-5 bgi-size-cover bgi-no-repeat
                // style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h3 className="text-white font-weight-bold mb-0">
                  {menu.title}
                </h3>
                {/* <span className="btn btn-success btn-sm btn-bold btn-font-md mt-2">
                    23 tasks pending
                  </span> */}
              </div>
  
              <div className="row no-gutters">{/* row-paddingless */}
                {menu?.items?.map((itemVal, idx) => (
                  <div key={idx} className="col-6">
                    <NavLink // eslint-disable-next-line
                      to={itemVal.href} 
                      className={Cx(
                        "d-block py-10 px-5 text-center bg-hover-light",
                        style["content-quick-setting"],
                        {
                          "border-right":
                            (idx === 0 && idx + 1 !== menu.items.length) ||
                            (idx + 1) % 2 !== 0,
                          "border-bottom":
                            (idx === 0 && idx + 1 !== menu.items.length) ||
                            (idx + 1) % 2 === 0,
                        }
                      )}
                    >
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        <SVG title=" " src={toAbsoluteUrl(itemVal.icon)}></SVG>
                      </span>
                      <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                        {itemVal.title}
                      </span>
                      {/* <span className="d-block text-dark-50 font-size-lg">
                        {menu.title}
                      </span> */}
                    </NavLink>
                  </div>
                ))}
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )
      : null;
  };

  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="quick-actions-tooltip">Quick actions</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
              id="kt_quick_actions_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  title=" "
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}
      
      {!layoutProps.offcanvas && (menus || []).map((val) => renderPanel(val))}
    </>
  );
}
