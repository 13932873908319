import React from "react";
// import SVG from "react-inlinesvg";

// import { toAbsoluteUrl } from "../../../_helpers";

export function ScrollTop() {
  return (
    <div id="kt_scrolltop" className="scrolltop far fa-arrow-up fa-lg text-white">
      {/* <span className="svg-icon">
        <SVG title=" " src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-2.svg")} />
      </span>{" "} */}
    </div>
  );
}
