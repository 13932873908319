import axios from 'axios';
export const APP_MENU_URL = "/api/sys/permissions/menu";
export const CATEGORY_URL = "/api/form/Editor/category";

export function getTree() {
    return axios.get(`${APP_MENU_URL}/sidebar/tree`);
}

export function getMenu() {
    return axios.get(`${APP_MENU_URL}/sidebar`);
}

export function orderIndex(param) {
    return axios.put(`${APP_MENU_URL}/sidebar/order`, param);
}

export function saveMenu(menu) {
    return axios.post(`${CATEGORY_URL}/save`, menu);
}

export function deleteMenu(id) {
    return axios.post(`${CATEGORY_URL}/${id}/delete`);
}

export function getDetail(id) {
    return axios.get(`${CATEGORY_URL}/${id}/detail`);
}

export function publish(id) {
    return axios.post(`${APP_MENU_URL}/Publish`, id);
}