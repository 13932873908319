import AES from "crypto-js/aes";
import encUTF8 from "crypto-js/enc-utf8";

export const generateKey = () => {
	const dateTime = new Date();
	const formattedDate = dateTime
	  .toISOString()
	  .slice(0, 16)
	  .replace(/[-T:]/g, ""); // Sesuaikan format tanggal
	return formattedDate.padEnd(16, "0");
};

export const encrypt = (plainText, secretKey) => {
    if(!secretKey) secretKey = generateKey()
    const key = encUTF8.parse(secretKey);
    const iv = key; // Sesuaikan ukuran IV dengan ukuran blok

    const encryptedData = AES.encrypt(plainText, key, { iv });

    return encryptedData.toString();
};

export const decrypt = (cipherText, secretKey) => {
    if(!secretKey) secretKey = generateKey()
    const key = encUTF8.parse(secretKey);
    const iv = key; // Sesuaikan ukuran IV dengan ukuran blok

    const decryptedData = AES.decrypt(cipherText, key, { iv });

    return decryptedData.toString(encUTF8);
};