const workflowDefinitionEndpoints = {
    createWorkflowDefinition(params) {
        return {
            url: '/Elsa/workflow-definition/create',
            method: 'POST',
            params,
        }
    },
    getWorkflowInstanceDetails(id) {
        return {
            url: `/Elsa/workflow-instance/details/c4ec1273869d4d25bc442088bb37f943`,
            method: 'GET',
        }
    },
}

export default workflowDefinitionEndpoints