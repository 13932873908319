import { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initRequest } from "../actionMethods";

const initialState = { loading: {} };
const reducer = (state, action) => {
    const { key, status } = action.payload
    switch (action.type) {
        case 'SET_LOADING':
            return {
                loading: {
                    ...state.loading,
                    [key]: status,
                }
            };
        default:
            return state
    }
}
const useApiRequest = (options = {}) => {
    const { redux = false, fieldId = '', normalizer } = options
    const reduxState = useSelector(state => state.formEditorReducer);
    const reduxDispatch = useDispatch()
    const [state, dispatch] = useReducer(reducer, initialState)
    const dispatcher = redux ? reduxDispatch : dispatch
    const loadingState = redux ? reduxState : state
    const request = initRequest(dispatcher, fieldId, normalizer)
    return {
        request,
        loading: loadingState.loading,
    }
}

export default useApiRequest