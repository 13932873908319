import React from "react";// , , { useState }
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import message from "@utils/message";
import formEditorActions from '@actions/formEditorActions';
import CreateFromScratchModal from "../../../../../app/components/form-editor/components/CreateFromScratchModal";
import { Qid } from '@utils/Q';
import { INIT_FIELD_TYPES } from "../../../../../app/components/form-editor/const";

const INIT_FORM_FIELD = { ...INIT_FIELD_TYPES[0] }; // fid: Qid()
// const INIT_EMPTY_COL = { columnWidth: 8, fieldType: "empty-col" };

const CreateModule = ({
  show = false, 
  onClose = () => {}, 
  ...props
}) => {
  // const [loadingForms, setLoadingForms] = useState(false);
  // const [newModulePayload, setNewModulePayload] = useState({});

  const onCreateFromScratch = async (payload, fn) => {
    const newFields = [{ ...INIT_FORM_FIELD, fid: Qid() }];

    payload.machineLearningServiceId = JSON.stringify(payload.machineLearningServiceId);  

    const { data } = await props.createProcessFromScratch(payload);
    if (data?.success) {
      await props.getFormList();
      props.setFields(newFields);
      fn.setSubmitting(false);
      
      onClose();
      setTimeout(() => {
        props.history.push({
          pathname: "/form-editor/show/" + data?.data?.id,
          state: { modal: {} },
        });
      }, 150);
    }else{
      message.error(data?.message);
      fn.setSubmitting(false);
    }
  }

  return (
    <CreateFromScratchModal
      show={show} 
      onClose={onClose} // this.onCancelFromScratch
      onCreate={onCreateFromScratch}
      formType={"APP"}
    />
  )
}

const mapStateToProps = (state) => ({
  fields: state.formEditorReducer.fields,
  formDetails: state.formEditorReducer.formDetails,
  formList: state.formEditorReducer.formList,
  moduleCategories: state.formEditorReducer.moduleCategories,
});
const mapDispatchToProps = {
  ...formEditorActions,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateModule));
