import store from "./store";
import { actions } from "../app/modules/Auth/_redux/authRedux";
import message from "../utils/message";

export const requestRejectInterceptor = err => Promise.reject(err)
export const requestInterceptor = store => config => {
    const {
        auth: { authToken }
    } = store.getState();

    if (authToken && config?.headers?.[config?.method]?.['Content-Type'] !== "application/xml") {
        config.headers.Authorization = `Bearer ${authToken}`;
    }

    if (process.env.REACT_APP_BASE_API_URL.match(/\/DUMMY/) && !config.url.match(/\/api/)) {
        config.url = `${config.url}.json`
        config.method = 'GET'
    }

    return config;
}
export const responseRejectInterceptor = err => {
    if (err.response) {
        const { status } = err.response;
        if (status === 401) {
            store.dispatch(actions.logout())
        }
    }
    if (err.code === 'ECONNABORTED') {
        message.error(err.message)
    }
}
export const responseInterceptor = response => response

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(requestInterceptor(store));

  axios.interceptors.response.use(
    responseInterceptor,
    responseRejectInterceptor
  );
}
